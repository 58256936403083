import { getErrorPayload } from '@application/common/helpers/api-helper';
import UserService from '@infrastructure/user-api';
import InvoiceService from '@infrastructure/invoice-api';
import FreelancerService from '@infrastructure/freelancer-api';
import DebtorService from '@infrastructure/debtor-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addSuccessNotification } from '../common/notificationSlice';
import { toggleDisableUserConfirm, toggleEnableUserConfirm, toggleUserDetailsModal, toggleUserOutStandingBalanceModal } from './adminUserSlice';
export const getUsersAsync = createAsyncThunk('users/getAll', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await UserService.getUsers(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserByIdAsync = createAsyncThunk('users/getUserById', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await UserService.getUserById(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateUserAsync = createAsyncThunk('users/updateUserProfile', async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await UserService.updateUser(request.id, request);
        dispatch(toggleUserDetailsModal());
        dispatch(addSuccessNotification('Profile updated successfully!'));
        const { getUserDetailsRequest } = getState().user;
        dispatch(getUsersAsync(getUserDetailsRequest));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserInvoicesAsync = createAsyncThunk('users/userInvoiceById', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceService.getUserInvoicesById(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserDebtorsAsync = createAsyncThunk('users/userDebtorById', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorService.getUserDebtorsById(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserBalanceAsync = createAsyncThunk('users/userBalanceById', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FreelancerService.getFreelancerBalance(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateUserBalanceAsync = createAsyncThunk('users/updateBalanceById', async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FreelancerService.updateFreelancerBalance(request);
        dispatch(toggleUserOutStandingBalanceModal());
        dispatch(addSuccessNotification('Balance updated successfully!'));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const disableUserAsync = createAsyncThunk('users/disableUser', async (userId, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await UserService.disableUser(userId);
        dispatch(addSuccessNotification('User disabled successfully!'));
        const getUsersRequest = getState().user.getUserDetailsRequest;
        dispatch(toggleDisableUserConfirm());
        dispatch(getUsersAsync(getUsersRequest));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const enableUserAsync = createAsyncThunk('users/enableUser', async (userId, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await UserService.enableUser(userId);
        dispatch(addSuccessNotification('User enabled successfully!'));
        const getUsersRequest = getState().user.getUserDetailsRequest;
        dispatch(toggleEnableUserConfirm());
        dispatch(getUsersAsync(getUsersRequest));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
