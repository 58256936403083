import { getErrorPayload } from '@application/common/helpers/api-helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import UserTypeApi from '@infrastructure/userType-api';
export const getUserTypeAsync = createAsyncThunk('userType/getAll', async (_, { rejectWithValue }) => {
    try {
        const response = await UserTypeApi.getUserType();
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
