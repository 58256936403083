import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { loginAsync } from '@application/features/auth/authThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqValidation from '@ui/components/FqValidation';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
const Login = () => {
    const { currentUser, currentUserDetail, apiError } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (currentUser) {
            if (['Admin', 'SuperAdmin'].includes(currentUser.role))
                navigate('/admin/outstanding-invoices');
        }
    }, [currentUser, navigate]);
    useEffect(() => {
        if (currentUser && currentUserDetail) {
            if (currentUser.role === 'Freelancer') {
                if (currentUserDetail?.accountApproved)
                    navigate('/');
                else
                    navigate('/account');
            }
        }
    }, [currentUser, currentUserDetail, navigate]);
    const { control, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            email: '',
            password: '',
            rememberMe: false
        }
    });
    const onSubmit = (credentials) => dispatch(loginAsync(credentials));
    return (_jsx("div", { className: 'singin__container', children: _jsxs("div", { className: 'singin__page', children: [_jsx("div", { className: 'singin__page__left', children: _jsx("div", { className: 'singin__page__main', children: _jsxs("div", { className: 'form__inner', children: [_jsxs("div", { className: 'from__title', children: [_jsx("h2", { children: translate('account.welcomeBack') }), _jsx("p", { children: translate('account.welcomeBackMessage') })] }), apiError && apiError.status === 401 && (_jsx("div", { className: 'alert alert-danger', role: 'alert', children: apiError.error })), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: 'contact-form', noValidate: true, children: _jsxs("div", { className: 'all__from', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.email') }), _jsx(Controller, { name: 'email', control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate('validations.fieldIsRequired')
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: translate('validations.invalidEmailAddress')
                                                            }
                                                        }, render: ({ field: { onChange, value } }) => (_jsx("input", { type: 'email', name: 'email', onChange: onChange, value: value })) }), _jsx(FqValidation, { fieldError: errors.email })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.password') }), _jsx(Controller, { name: 'password', control: control, rules: {
                                                            required: {
                                                                value: true,
                                                                message: translate('validations.fieldIsRequired')
                                                            }
                                                        }, render: ({ field: { onChange, value } }) => (_jsx("input", { type: 'password', name: 'password', onChange: onChange, value: value })) }), _jsx(FqValidation, { fieldError: errors.password })] }), _jsx("div", { className: 'form-group', children: _jsxs("div", { className: 'password___forget', children: [_jsxs("div", { className: 'form-check', children: [_jsx(Controller, { name: 'rememberMe', control: control, render: ({ field: { onChange, value } }) => (_jsx("input", { className: 'form-check-input', type: 'checkbox', id: 'remember-check', onChange: onChange, checked: value })) }), _jsx("label", { className: 'form-check-label', htmlFor: 'remember-check', style: { marginTop: '2.5px' }, children: translate('account.rememberMe') })] }), _jsx("div", { className: 'forget__password', children: _jsx(Link, { to: '/forgot-password', children: translate('account.forgotPassword') }) })] }) }), _jsx("div", { className: 'submit___btn', children: _jsx("div", { className: 'signin__button', children: _jsx("button", { className: 'btn__one', type: 'submit', name: 'submit__form', children: _jsx("span", { className: 'text', children: translate('account.signin') }) }) }) })] }) }), _jsx("div", { className: 'form__bottom', children: _jsxs("p", { children: [translate('account.dontHaveAnAccount'), " ", _jsx(Link, { to: '/sign-up', children: translate('account.signup') })] }) })] }) }) }), _jsx("div", { className: 'singin__page__right', children: _jsx("div", { className: 'logo', children: _jsx("img", { src: "images/faqtoring-login.jpg", alt: "Login page" }) }) })] }) }));
};
export default Login;
