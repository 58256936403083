import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getFaqsAsync } from './faqThunks';
const initialState = {
    status: APIStatus.IDLE,
    faqs: []
};
export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFaqsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFaqsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.faqs = action.payload;
        })
            .addCase(getFaqsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default faqSlice.reducer;
