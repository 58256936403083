import { getErrorPayload } from '@application/common/helpers/api-helper';
import FaqService from '@infrastructure/faqs-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getFaqsAsync = createAsyncThunk('faqs/getAll', async (_, { rejectWithValue }) => {
    try {
        const response = await FaqService.getFaqs();
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
