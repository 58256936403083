import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmEmail from '../auth/ConfirmEmail';
import ForgotPassword from '../auth/ForgotPassword';
import Login from '../auth/Login';
import { SignUp } from '../auth/SignUp';
import SignUpSuccess from '../auth/SignUpSuccess';
import Dashboard from '../freelancer/dashboard/index';
import Debtors from '../freelancer/debtors';
import AdminDebtors from '../admin/debtors';
import Invoices from '../freelancer/invoices';
import Layout from './Layout';
import ProtectedRoute from './ProtectedRoute';
import Contact from '../freelancer/support';
import ResetPasswordEmailSent from '../auth/ResetPasswordEmailSent';
import ResetPassword from '../auth/ResetPassword';
import ResetPasswordSuccess from '../auth/ResetPasswordSuccess';
import Users from '../admin/users';
import OutstandingInvoices from '../admin/invoices/outstandingInvoices';
import ProcessedInvoices from '../admin/invoices/processedInvoices';
import ReceivedInvoices from '../admin/invoices/receivedInvoices';
import InvoicesByStatus from '../freelancer/invoices/invoicesByStatus';
import InvalidResetPassword from '../auth/InvalidResetPassword';
import Account from '../freelancer/account';
import ShufftiPro from '../freelancer/shuffti-pro';
const AppRoutes = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: 'login', element: _jsx(Login, {}) }), _jsx(Route, { path: 'sign-up', element: _jsx(SignUp, {}) }), _jsx(Route, { path: 'sign-up-success', element: _jsx(SignUpSuccess, {}) }), _jsx(Route, { path: 'email-confirmation', element: _jsx(ConfirmEmail, {}) }), _jsx(Route, { path: 'forgot-password', element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: 'reset-password-email', element: _jsx(ResetPasswordEmailSent, {}) }), _jsx(Route, { path: 'reset-password', element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: 'reset-password-success', element: _jsx(ResetPasswordSuccess, {}) }), _jsx(Route, { path: 'link-expired', element: _jsx(InvalidResetPassword, {}) }), _jsxs(Route, { element: _jsx(Layout, {}), children: [_jsxs(Route, { path: '/', element: _jsx(ProtectedRoute, { requiredRoles: ['Freelancer'] }), children: [_jsx(Route, { index: true, element: _jsx(Dashboard, {}) }), _jsx(Route, { path: 'account', element: _jsx(Account, {}) }), _jsx(Route, { path: 'debtors', element: _jsx(Debtors, {}) }), _jsx(Route, { path: 'invoices', element: _jsx(Invoices, {}) }), _jsx(Route, { path: 'invoices/:id', element: _jsx(InvoicesByStatus, {}) }), _jsx(Route, { path: 'support', element: _jsx(Contact, {}) }), _jsx(Route, { path: 'sp-notify-redirect', element: _jsx(ShufftiPro, {}) })] }), _jsxs(Route, { path: 'admin', element: _jsx(ProtectedRoute, { requiredRoles: ['Admin', 'SuperAdmin'] }), children: [_jsx(Route, { path: 'outstanding-invoices', element: _jsx(OutstandingInvoices, {}) }), _jsx(Route, { path: 'processed-invoices', element: _jsx(ProcessedInvoices, {}) }), _jsx(Route, { path: 'received-invoices', element: _jsx(ReceivedInvoices, {}) }), _jsx(Route, { path: 'debtors', element: _jsx(AdminDebtors, {}) }), _jsx(Route, { path: 'users', element: _jsx(Users, {}) })] }), _jsx(Route, { path: '*', element: _jsx(Navigate, { to: '/', replace: true }) })] })] }));
};
export default AppRoutes;
