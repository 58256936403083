import { faqtoringApi } from './faqtoring-api';
import { convertModelToFormData } from './helpers';
const createDebtor = (debtor) => {
    const formData = convertModelToFormData(debtor);
    return faqtoringApi.postForm('debtor', formData);
};
const getUserDebtors = () => {
    return faqtoringApi.get('debtor/UserDebtors');
};
const getUsersAcceptedDebtors = () => {
    return faqtoringApi.get('debtor/UsersAcceptedDebtors');
};
const uploadSignedFaqtroignContract = (signedContract) => {
    const formData = convertModelToFormData(signedContract);
    return faqtoringApi.putForm('debtor/UploadSignedFactoringContract', formData);
};
const getInactiveDebtors = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`debtor/GetInactiveDebtors?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getActiveDebtors = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`debtor/GetActiveDebtors?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const updateDebtorStatus = (status) => {
    return faqtoringApi.put('debtor/UpdateDebtorStatus', status);
};
const getUserDebtorsById = (userId) => {
    return faqtoringApi.get(`debtor/GetDebtorsByUserId/${userId}`);
};
const genrateFactoringContract = (request) => {
    return faqtoringApi.put('debtor/GenrateFactoringContract', request);
};
const updateGenratedFactoringContractStatus = (request) => {
    return faqtoringApi.put('debtor/UpdateGenratedFactoringContractStatus', request);
};
const getDebtorById = (debtorId) => {
    return faqtoringApi.get(`debtor/getDebtorById/${debtorId}`);
};
const DebtorApi = {
    createDebtor,
    getUserDebtors,
    uploadSignedFaqtroignContract,
    getInactiveDebtors,
    getActiveDebtors,
    updateDebtorStatus,
    getUserDebtorsById,
    getUsersAcceptedDebtors,
    genrateFactoringContract,
    updateGenratedFactoringContractStatus,
    getDebtorById
};
export default DebtorApi;
