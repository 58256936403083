import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { toggleUserDebtorsModal } from '@application/features/adminUsers/adminUserSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import { useEffect, useState } from 'react';
import '../style.css';
import UserDebtorsList from './userDebtors';
import { toggleApproveFaqtoringContractModal, toggleGenerateFaqtoringContractModal, toggleViewDebtorModal } from '@application/features/debtors/debtorSlice';
import { downloadDebtorContractAsync, generateFaqtoringContractAsync, getDebtorByIdAsync } from '@application/features/debtors/debtorThunks';
import FqDialog from '@ui/components/FqDialog';
import ApproveSignedContractForm from '../../debtors/ApproveSignedContractForm';
import GenerateContractForm from '../../debtors/GenerateContractForm';
import { DebtorDocumentType } from '@application/common/enums/debtor-document-type';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { getUserDebtorsAsync } from '@application/features/adminUsers/adminUserThunk';
const UserDebtors = ({ filteredDebtors, status, userId }) => {
    const dispatch = useAppDispatch();
    const [filteredDebtorsList, setFilteredDebtorsList] = useState(filteredDebtors);
    useEffect(() => {
        setFilteredDebtorsList(filteredDebtors);
    }, [filteredDebtors]);
    const viewDebtor = (debtorId) => {
        dispatch(getDebtorByIdAsync(debtorId));
        dispatch(toggleViewDebtorModal());
    };
    const [signContractState, setSignContractState] = useState();
    const onApproveFaqtoringContract = (debtorId, debtorName) => {
        setSignContractState({ debtorId, debtorName });
        dispatch(toggleApproveFaqtoringContractModal());
    };
    const onGenerateFaqtoringContract = (debtorId, freelancerId) => {
        dispatch(generateFaqtoringContractAsync({ debtorId, freelancerId }))
            .unwrap()
            .then((response) => {
            const faqtoringContractList = response.debtorDocuments.filter((i) => i.type === DebtorDocumentType.FaqtoringContract);
            if (faqtoringContractList !== null && faqtoringContractList !== undefined) {
                setGenerateContractState({ debtorId, freelancerId, faqtoringContractList });
            }
        });
    };
    const downloadSignedFaqtoringContract = (debtorId) => {
        const debtor = filteredDebtorsList && filteredDebtorsList.find((x) => x.debtorId === debtorId);
        if (debtor) {
            const contractDocument = debtor?.debtorDocuments.find((x) => x.type === DebtorDocumentType.SignedFaqtoringContract);
            const fileDownloadInput = { folderName: contractDocument?.folderName, fileName: contractDocument?.name };
            dispatch(downloadDebtorContractAsync(fileDownloadInput))
                .unwrap()
                .then((res) => {
                const mimeType = getMimeTypeFromBase64(res.file);
                const pdfWindow = window.open();
                pdfWindow?.document.write(`<title>SignedFaqtoringContract-${debtor.freelanerName}</title>`);
                pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
            });
        }
    };
    const onFaqtoringContractApproved = (debtorId) => {
        dispatch(getUserDebtorsAsync(userId))
            .unwrap()
            .then((res) => {
            setFilteredDebtorsList(res);
        });
    };
    const onFaqtoringContractGenrated = (debtorId) => {
        dispatch(getUserDebtorsAsync(userId))
            .unwrap()
            .then((res) => {
            setFilteredDebtorsList(res);
        });
    };
    const { approveFaqtoringContractModalOpen: approvedFaqtoringContractModalOpen, generateFaqtoringContractModalOpen } = useAppSelector((state) => state.debtor);
    const [generateContractState, setGenerateContractState] = useState();
    return (_jsxs("div", { className: 'debtor__form', children: [_jsx(FqGrid, { header: _jsx(FqGridHeader, { title: 'User Debtors' }), children: _jsx(_Fragment, { children: _jsx(UserDebtorsList, { filteredDebtors: filteredDebtorsList, status: status, onApproveFaqtoringContract: onApproveFaqtoringContract, onGenerateFaqtoringContract: onGenerateFaqtoringContract, viewDebtor: viewDebtor }) }) }), _jsx("div", { className: 'view__debtoe__popup__btn', children: _jsx(FqButton, { mode: 'danger', onClick: () => dispatch(toggleUserDebtorsModal()), children: "Cancel" }) }), approvedFaqtoringContractModalOpen && (_jsx(FqDialog, { open: approvedFaqtoringContractModalOpen, onClose: () => dispatch(toggleApproveFaqtoringContractModal()), title: 'Approve Signed Faqtoring Contract', maxWidth: 540, children: signContractState?.debtorId ? (_jsx(ApproveSignedContractForm, { debtorId: signContractState.debtorId, debtorName: signContractState?.debtorName, onDownloadSignedFaqtoringContract: downloadSignedFaqtoringContract, onFaqtoringContractApproved: onFaqtoringContractApproved })) : (_jsx(_Fragment, {})) })), generateFaqtoringContractModalOpen && (_jsx(FqDialog, { open: generateFaqtoringContractModalOpen, onClose: () => dispatch(toggleGenerateFaqtoringContractModal()), title: '', maxWidth: 540, children: _jsx(GenerateContractForm, { debtorId: generateContractState?.debtorId ? generateContractState?.debtorId : '', faqtoringContractList: generateContractState?.faqtoringContractList ? generateContractState?.faqtoringContractList : [], onFaqtoringContractGenrated: onFaqtoringContractGenrated }) }))] }));
};
export default UserDebtors;
