import { getErrorPayload } from '@application/common/helpers/api-helper';
import InvoiceApi from '@infrastructure/invoice-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPorcessedInvoicesRequest, toggleReceivedInvoiceModal } from './invoicesByStatusSlice';
import { addSuccessNotification } from '../common/notificationSlice';
export const getOutstandingInvoicesAsync = createAsyncThunk('invoices/getOutstanding', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getInvoicesByStatus(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getFreelancerOutstandingInvoicesAsync = createAsyncThunk('invoices/getFreelancerOutstanding', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getFreelancerOutstandingInvoices(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getProcessedInvoicesAsync = createAsyncThunk('invoices/getProcessed', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getProcessedInvoices(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getReceivedInvoicesAsync = createAsyncThunk('invoices/getReceived', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getInvoicesByStatus(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const approvedProcessedInvoiceAsync = createAsyncThunk('invoices/updateProcessedInvoice', async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await InvoiceApi.approvedProcessedInvoice(request.invoiceId, request.date);
        dispatch(toggleReceivedInvoiceModal());
        dispatch(addSuccessNotification('Amount received successfully!'));
        const processedInvoicesRequest = getState().invoiceByStatus.processedInvoicesRequest;
        dispatch(setPorcessedInvoicesRequest({ ...processedInvoicesRequest }));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
