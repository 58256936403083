import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import FqAccordion from '@ui/components/FqAccordion';
import { useState } from 'react';
const FaqList = () => {
    const faqKeys = [
        'whatAreCosts',
        'howToUploadInvoice',
        'whyAddDebtor',
        'whatIsBalance',
        'whatIsValidProofForInvoice',
        'howQuicklyIGetPaid',
        'whatIfDebtorDoesNotPay'
    ];
    const [openKey, setOpenKey] = useState('0');
    const handleToggle = (key) => {
        setOpenKey(openKey !== key ? key : '');
    };
    return (_jsxs("div", { className: 'faq__part', children: [_jsx("div", { className: 'faq__title', children: _jsx("h3", { children: translate('support.frequentlyAskedQuestions') }) }), _jsx("div", { className: 'faq-content-box', children: _jsx("div", { className: 'accordion-box-style1', children: faqKeys?.map((faq, index) => (_jsx(FqAccordion, { question: translate(`faqs.${faq}.heading`), answer: translate(`faqs.${faq}.content`), id: index.toString(), open: openKey === index.toString(), toggle: handleToggle }, index))) }) })] }));
};
export default FaqList;
