const AUTH_TOKEN = 'AUTH_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const REFERENCE_NUMBER = 'REFERENCE_NUMBER';
const SHUFFTIPRO = 'SHUFFTIPRO';
const setAccessToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
};
const setRefreshToken = (token) => {
    localStorage.setItem(REFRESH_TOKEN, token);
};
const setReferenceNumber = (refNumber) => {
    localStorage.setItem(REFERENCE_NUMBER, refNumber);
};
const setShufftiProStatus = (shufftiPro) => {
    localStorage.setItem(SHUFFTIPRO, shufftiPro);
};
const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
};
const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
};
const getReferenceNumber = () => {
    return localStorage.getItem(REFERENCE_NUMBER);
};
const getShufftiProStatus = () => {
    return localStorage.getItem(SHUFFTIPRO);
};
const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};
const removeRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};
const removeReferenceNumber = () => {
    localStorage.removeItem(REFERENCE_NUMBER);
};
const removeShufftiProStatus = () => {
    localStorage.removeItem(SHUFFTIPRO);
};
const LocalStorageService = {
    setAccessToken,
    setRefreshToken,
    setReferenceNumber,
    setShufftiProStatus,
    getAccessToken,
    getRefreshToken,
    getReferenceNumber,
    getShufftiProStatus,
    removeAccessToken,
    removeRefreshToken,
    removeReferenceNumber,
    removeShufftiProStatus
};
export default LocalStorageService;
