import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { resetPasswordAsync, verifyTokenAsync } from '@application/features/auth/authThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import FqButton from '@ui/components/FqButton';
import FqValidation from '@ui/components/FqValidation';
import { translate } from '@application/common/helpers/translate';
import { VerifyTokenType } from '@application/common/enums/verify-token-type';
const ResetPassword = () => {
    const dispatch = useAppDispatch();
    const { status } = useAppSelector((state) => state.auth);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const resetToken = searchParams.get('token');
    const resetEmail = searchParams.get('email');
    const { register, watch, handleSubmit, setValue, formState: { errors } } = useForm();
    const validatePassword = (password) => {
        if (password.length < 8)
            return translate('validations.mustHaveEightCharacters');
        if (!/[A-Z]+/.test(password))
            return translate('validations.mustHaveUpperCaseLetter');
        if (!/[a-z]+/.test(password))
            return translate('validations.mustHaveLowerCaseLetter');
        if (!/[0-9]+/.test(password))
            return translate('validations.mustHaveOneNumber');
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return translate('validations.mustHaveSpecialCharacter');
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch('password') !== confirmPassword)
            return translate('validations.passwordDonotMatch');
    };
    useEffect(() => {
        if (resetToken && resetEmail) {
            dispatch(verifyTokenAsync({
                email: resetEmail,
                token: resetToken.replaceAll(' ', '+'),
                type: VerifyTokenType.Reset
            }))
                .unwrap()
                .then((response) => {
                if (!response) {
                    navigate('/link-expired');
                }
            });
            setValue('token', resetToken.replaceAll(' ', '+'));
            setValue('email', resetEmail);
        }
    }, [resetEmail, resetToken, setValue]);
    const onSubmit = (credentials) => {
        dispatch(resetPasswordAsync(credentials))
            .unwrap()
            .then((response) => {
            if (response) {
                navigate('/reset-password-success');
            }
        });
    };
    return (_jsxs("div", { className: 'reset__password__page', children: [_jsx("div", { className: 'logo d-none', children: _jsx("figure", { className: 'image', children: _jsx("img", { src: 'images/logo.svg', alt: 'logo' }) }) }), _jsx("div", { className: 'from__title text-center', children: _jsx("h2", { children: translate('account.resetPassword') }) }), _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs("div", { className: 'all__from', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.newPassword') }), _jsx("input", { type: 'password', ...register('password', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        validate: (value) => validatePassword(value)
                                    }) }), _jsx(FqValidation, { fieldError: errors.password })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.confirmNewPassword') }), _jsx("input", { type: 'password', ...register('confirmPassword', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        validate: (value) => validateConfirmPassword(value)
                                    }) }), _jsx(FqValidation, { fieldError: errors.confirmPassword })] }), _jsx(FqButton, { type: 'submit', mode: 'primary', size: 'lg', children: translate('account.updatePassword') })] }) })] }));
};
export default ResetPassword;
