import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toggleResponsiveMenu } from '@application/features/layout/layoutSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import ResponsiveMenu from './Menu/ResponsiveMenu';
import SidebarMenu from './Menu/SidebarMenu';
const Layout = () => {
    const { currentUser, currentUserDetail } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const { responsiveMenuOpen } = useAppSelector((state) => state.layout);
    return (_jsx("div", { className: 'auto__container px-md-4', children: _jsxs("div", { className: 'dashboard__page', children: [_jsx(Header, { currentUser: currentUser, balance: currentUserDetail?.currentBalance }), _jsx(ResponsiveMenu, { userRole: currentUser?.role, userApproved: currentUserDetail?.accountApproved, isOpen: responsiveMenuOpen, onClose: () => dispatch(toggleResponsiveMenu()), isUserDebtorExists: currentUserDetail?.isUserDebtorExists }), _jsxs("div", { className: 'dashboard__body account debetors', children: [_jsx(SidebarMenu, { userRole: currentUser?.role, userApproved: currentUserDetail?.accountApproved, isUserDebtorExists: currentUserDetail?.isUserDebtorExists }), _jsx("div", { className: 'dashboard__right', children: _jsx("div", { className: 'debetors__section', children: _jsx(Outlet, {}) }) })] })] }) }));
};
export default Layout;
