import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FreelancerBalance from './FreelancerBalance';
import LanguagesDropDown from './LanguagesDropDown';
import ResponsiveHeader from './ResponsiveHeader';
import UserProfileDropDown from './UserProfileDropDown';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
const Header = ({ currentUser, balance = 0 }) => {
    const navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: 'dashboard__header', children: [_jsxs("div", { className: 'header__left', children: [_jsx("div", { className: 'logo', children: _jsx("figure", { children: _jsx("button", { className: 'btn btn-link p-0', onClick: () => currentUser?.role === 'Freelancer' ? navigate('/') : navigate('/admin/outstanding-invoices'), children: _jsx("img", { src: 'images/logo.svg', alt: 'logo' }) }) }) }), currentUser?.role === 'Freelancer' && _jsx(FreelancerBalance, { balance: balance })] }), _jsxs("div", { className: 'header__right', children: [_jsx("div", { className: 'date', children: _jsx("p", { children: moment(new Date()).format('dddd, DD MMMM, YYYY') }) }), currentUser?.role === 'Freelancer' && _jsx(LanguagesDropDown, {}), _jsx(UserProfileDropDown, { name: currentUser?.name, role: currentUser?.role })] })] }), _jsx(ResponsiveHeader, { balance: balance, currentUser: currentUser })] }));
};
export default Header;
