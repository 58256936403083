import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { translate } from '@application/common/helpers/translate';
import { resetAuthApiState } from '@application/features/auth/authSlice';
import { forgotPasswordAsync } from '@application/features/auth/authThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqValidation from '@ui/components/FqValidation';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { status, apiError } = useAppSelector((state) => state.auth);
    const { register, handleSubmit, formState: { errors } } = useForm();
    useEffect(() => {
        if (status === APIStatus.SUCCESS) {
            dispatch(resetAuthApiState());
            navigate('/reset-password-email');
        }
    }, [dispatch, navigate, status]);
    const onSubmit = (input) => dispatch(forgotPasswordAsync(input));
    return (_jsxs("div", { className: 'forget__password__page', children: [_jsx("div", { className: 'logo d-none', children: _jsx("figure", { className: 'image', children: _jsx("img", { src: 'images/logo.svg', alt: 'logo' }) }) }), _jsxs("div", { className: 'from__title text-center', children: [_jsxs("h2", { children: [translate('account.forgotPassword'), "?"] }), _jsx("p", { children: translate('account.forgotPasswordMessage') })] }), apiError && apiError.status === 401 && (_jsx("div", { className: 'alert alert-danger', role: 'alert', children: apiError.error })), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: 'contact-form', noValidate: true, children: _jsxs("div", { className: 'all__from', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: "Email" }), _jsx("input", { type: 'text', ...register('email', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: translate('validations.invalidEmailAddress')
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.email })] }), _jsx("div", { className: 'submit___btn', children: _jsx("div", { className: 'signin__button', children: _jsx("button", { className: 'btn__one', type: 'submit', name: 'submit__form', children: _jsx("span", { className: 'text', children: translate('account.resetPassword') }) }) }) })] }) })] }));
};
export default ForgotPassword;
