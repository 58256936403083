import { getErrorPayload } from '@application/common/helpers/api-helper';
import AuthApi from '@infrastructure/auth-api';
import LocalStorageService from '@infrastructure/local-storage-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const loginAsync = createAsyncThunk('auth/login', async (credentials, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.login(credentials);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const refreshTokenAsync = createAsyncThunk('auth/refreshToken', async (_, { rejectWithValue }) => {
    try {
        const refreshTokenRequest = {
            accessToken: LocalStorageService.getAccessToken(),
            refreshToken: LocalStorageService.getRefreshToken()
        };
        const response = await AuthApi.getRefreshToken(refreshTokenRequest);
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const signUpAsync = createAsyncThunk('auth/signUp', async (user, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.signUp(user);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const confirmEmailAsync = createAsyncThunk('auth/confirmEmail', async (confirmation, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.confirmEmail(confirmation);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const forgotPasswordAsync = createAsyncThunk('auth/forgotPassword', async (input, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.forgotPassword(input);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const resetPasswordAsync = createAsyncThunk('auth/resetPassword', async (input, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.resetPassword(input);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const verifyTokenAsync = createAsyncThunk('auth/verifyToken', async (VerifyTokenDto, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await AuthApi.verifyToken(VerifyTokenDto);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
