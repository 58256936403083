import { resetUsers } from '@application/features/adminUsers/adminUserSlice';
import { resetDebtors, resetFreelancerDebtors } from '@application/features/debtors/debtorSlice';
import { resetFreelancerAccount } from '@application/features/freelancer/freelancerSlice';
import { resetFreelancerInvoices } from '@application/features/invoices/invoiceSlice';
import { resetOutstandingInvoices, resetProcessedInvoices, resetReceivedInvoices } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import { store } from '@application/store';
export const resetStateByPathName = (pathName) => {
    switch (pathName) {
        case '/admin/outstanding-invoices':
            store.dispatch(resetOutstandingInvoices());
            break;
        case '/admin/processed-invoices':
            store.dispatch(resetProcessedInvoices());
            break;
        case '/admin/received-invoices':
            store.dispatch(resetReceivedInvoices());
            break;
        case '/admin/users':
            store.dispatch(resetUsers());
            break;
        case '/admin/debtors':
            store.dispatch(resetDebtors());
            break;
        case '/debtors':
            store.dispatch(resetFreelancerDebtors());
            break;
        case '/invoices':
            store.dispatch(resetFreelancerInvoices());
            break;
        case '/account':
            store.dispatch(resetFreelancerAccount());
            break;
        default:
            break;
    }
};
