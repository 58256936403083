import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getUserTypeAsync } from './userTypeThunk';
const initialState = {
    status: APIStatus.IDLE
};
export const UserTypeStateSlice = createSlice({
    name: 'userType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserTypeAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserTypeAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.userTypes = action.payload;
            state.userTypesDropDown = action.payload.map((userType) => {
                return {
                    label: `${userType.name}`,
                    value: userType.name
                };
            });
        })
            .addCase(getUserTypeAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default UserTypeStateSlice.reducer;
