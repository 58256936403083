import { jsx as _jsx } from "react/jsx-runtime";
import { logout } from '@application/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
const ProtectedRoute = ({ children, requiredRoles }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { currentUser, currentUserDetail } = useAppSelector((state) => state.auth);
    if (!currentUser)
        return _jsx(Navigate, { to: '/login', state: { from: location } });
    const userHasRequiredRole = currentUser && requiredRoles.includes(currentUser.role);
    if (!userHasRequiredRole) {
        dispatch(logout());
        return _jsx(Navigate, { to: '/login', state: { from: location } });
    }
    else if (location.pathname !== '/account' &&
        location.pathname !== '/support' &&
        currentUserDetail &&
        !currentUserDetail.accountApproved) {
        return _jsx(Navigate, { to: '/account' });
    }
    else if (location.pathname === '/invoices' && currentUserDetail && !currentUserDetail.isUserDebtorExists)
        return _jsx(Navigate, { to: '/account' });
    return children ? children : _jsx(Outlet, {});
};
export default ProtectedRoute;
