import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { InvoiceStatusMap } from '@application/common/enums/invoice-status';
import { translate } from '@application/common/helpers/translate';
import { useEffect } from 'react';
import '../style.css';
const UserInvoicesList = ({ userInvoices, status }) => {
    useEffect(() => { }, []);
    return (_jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Debtor" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Invoice Amount" }), _jsx("th", { children: "Payable Invoice Amount " })] }) }), userInvoices && userInvoices.length > 0 && (_jsx("tbody", { children: userInvoices.map((invoice) => {
                    return (_jsxs("tr", { children: [_jsx("td", { children: invoice.debtor.companyName }), _jsx("td", { children: InvoiceStatusMap.get(invoice.status) }), _jsx("td", { children: invoice.invoiceAmount.toLocaleString('nl', {
                                    maximumFractionDigits: 2
                                }) }), _jsx("td", { children: invoice.payableInvoiceAmount.toLocaleString('nl', {
                                    maximumFractionDigits: 2
                                }) })] }, invoice.id));
                }) })), status === APIStatus.IDLE && userInvoices && !userInvoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 5, children: translate('common.noRecordFound') }) }) }))] }));
};
export default UserInvoicesList;
