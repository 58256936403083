export const toTitleCase = (value) => {
    if (value !== undefined) {
        const newValue = removeExtraSpace(value);
        return newValue.split(' ')
            .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(' ');
    }
    return '';
};
const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
