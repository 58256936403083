import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getCountriesAsync } from './countryThunk';
const initialState = {
    status: APIStatus.IDLE
};
export const countryStateSlice = createSlice({
    name: 'country',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCountriesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCountriesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.countries = action.payload;
            state.countriesDropdown = action.payload.map((country) => {
                return {
                    label: `${country.name} (${country.code})`,
                    value: country.id
                };
            });
        })
            .addCase(getCountriesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default countryStateSlice.reducer;
