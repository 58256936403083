import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { Language, LanguageStatusMap } from '@application/common/enums/language';
import { SignedFaqtoringContractType } from '@application/common/enums/signed-faqtoring-contract-type';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { translate } from '@application/common/helpers/translate';
import { toggleSignAgreementModal } from '@application/features/debtors/debtorSlice';
import { downloadTermsAndConditionsAsync, uploadSignedContractAsync } from '@application/features/debtors/debtorThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FqButton from '@ui/components/FqButton';
import FqValidation from '@ui/components/FqValidation';
import { selectStyles } from '@ui/styles/select-style';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
const SignAgreementForm = ({ debtorId }) => {
    const { i18n } = useTranslation();
    const { status } = useAppSelector((state) => state.debtor);
    const signatureTypes = [
        {
            label: translate('debtor.uploadSignedAgreement'),
            value: 'UPLOAD'
        },
        {
            label: translate('debtor.digitalSignature'),
            value: 'SIGN'
        }
    ];
    const [signatureType, setSignatureType] = useState(signatureTypes[1]);
    const languageList = [];
    Object.keys(Language)
        .filter((v) => isNaN(Number(v)))
        .forEach((key, index) => {
        languageList.push({ label: LanguageStatusMap.get(index), value: index.toString() });
    });
    const [contractLanguage, setContractLanguage] = useState(languageList[0]);
    const handlecontractLanguageChange = (option) => {
        setContractLanguage(option);
    };
    const dispatch = useAppDispatch();
    const { handleSubmit, watch, setValue, control, clearErrors, setError, register, formState: { errors } } = useForm({
        defaultValues: {
            debtorId: debtorId
        }
    });
    const validateSignedContract = () => {
        if (signatureType.value === 'UPLOAD' && uploadedFile === undefined)
            return translate('validations.fieldIsRequired');
    };
    const handleSignatureTypeChange = (option) => {
        if (option?.value === 'SIGN') {
            clearErrors('signedFaqtoringContract');
            setValue('signedFaqtoringContract', undefined);
        }
        else {
            signPad && signPad.current?.clear();
            setValue('digitalSignature', '');
        }
        setSignatureType(option);
    };
    const signPad = useRef(null);
    const clearSignature = () => {
        signPad && signPad.current?.clear();
        setValue('digitalSignature', '');
    };
    const uploadedFile = watch('signedFaqtoringContract');
    const uploadFile = (e) => {
        if (e.target.files && e.target.files.length) {
            const uploadedFile = e.target.files[0];
            if (!['image/jpeg', 'application/pdf'].includes(uploadedFile.type))
                setError('signedFaqtoringContract', {
                    message: translate('validations.filesNotAllowed')
                });
            else {
                setValue('signedFaqtoringContract', uploadedFile);
                clearErrors('signedFaqtoringContract');
            }
        }
        else {
            setValue('signedFaqtoringContract', undefined);
            clearErrors('signedFaqtoringContract');
        }
    };
    const downloadTermsAndConditions = () => {
        const selectedLanguage = i18n.language;
        const fileDownloadInput = {
            folderName: 'TemplateDocuments',
            fileName: 'en.freelancertermsandconditions.pdf'
        };
        if (selectedLanguage === 'nl') {
            fileDownloadInput.fileName = 'nl.freelancertermsandconditions.pdf';
        }
        dispatch(downloadTermsAndConditionsAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>TermsAndConditions</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    const onSubmit = (faqtoringContract) => {
        if (uploadedFile) {
            faqtoringContract.signedFactoringContractType = SignedFaqtoringContractType.Document;
        }
        else {
            faqtoringContract.signedFactoringContractType = SignedFaqtoringContractType.Signature;
        }
        if (contractLanguage.label === 'Dutch') {
            faqtoringContract.language = Language.NL;
        }
        else if (contractLanguage.label === 'English') {
            faqtoringContract.language = Language.EN;
        }
        dispatch(uploadSignedContractAsync(faqtoringContract));
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('debtor.signatureType') }), _jsx(Select, { name: 'signatureType', menuPortalTarget: document.body, isSearchable: false, isClearable: false, styles: selectStyles, value: signatureType, options: signatureTypes, onChange: (value) => handleSignatureTypeChange(value) })] }) }) }), signatureType.value === 'UPLOAD' && (_jsxs(_Fragment, { children: [_jsx("label", { className: 'file-upload-label align-self-center cussor-pointer', htmlFor: 'file-upload', children: _jsxs("div", { className: 'd-flex', children: [_jsx("div", { className: 'file-upload-icon', children: _jsx(FontAwesomeIcon, { icon: 'cloud-arrow-up', fontSize: '1.2em', color: '#FF0000' }) }), _jsx("h3", { className: 'align-self-center ms-2', children: translate('debtor.uploadSignedFaqtoringAgreement') })] }) }), _jsx(Controller, { name: 'signedFaqtoringContract', control: control, rules: {
                            validate: validateSignedContract
                        }, render: () => (_jsx("input", { accept: 'image/jpeg,application/pdf', type: 'file', id: 'file-upload', hidden: true, onChange: uploadFile })) }), uploadedFile && _jsx("div", { className: 'uploaded-file', children: uploadedFile?.name }), _jsx(FqValidation, { fieldError: errors.signedFaqtoringContract })] })), signatureType.value === 'SIGN' && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('debtor.faqtoringAgreementLanguage') }), _jsx(Select, { name: 'faqtoringAgreementLanguage', menuPortalTarget: document.body, isSearchable: false, isClearable: false, styles: selectStyles, value: contractLanguage, options: languageList, onChange: (value) => handlecontractLanguageChange(value) })] }) }) }), _jsx("div", { className: 'mt-5', children: _jsxs("div", { className: 'form-group signAgreement', children: [_jsx("label", { children: translate('debtor.signAgreement') }), _jsx("div", { style: { border: '1px solid', borderRadius: '5px' }, children: _jsx(Controller, { rules: {
                                            required: {
                                                value: true,
                                                message: translate('validations.fieldIsRequired')
                                            }
                                        }, name: 'digitalSignature', control: control, render: ({ field }) => (_jsx(SignatureCanvas, { ref: signPad, onEnd: () => field.onChange(signPad.current?.toDataURL()), velocityFilterWeight: 0.4, canvasProps: { className: 'sign-canvas' } })) }) }), _jsx(FqValidation, { fieldError: errors.digitalSignature }), _jsx(FqButton, { type: 'button', mode: 'secondary', size: 'sm', customClassName: 'mt-2', onClick: clearSignature, children: translate('debtor.clearSignature') })] }) })] })), _jsxs("div", { className: 'mt-5', children: [_jsxs("div", { className: 'form-check', children: [_jsx("input", { className: 'form-check-input', type: 'checkbox', id: 'flexCheckDefault', ...register('termsAgreed', {
                                    required: {
                                        value: true,
                                        message: translate('validations.mustAgreeToTerms')
                                    }
                                }) }), _jsx("label", { className: 'form-check-label', htmlFor: 'flexCheckDefault', style: { marginTop: '2.5px' }, children: translate('debtor.agreeToFaqtoring') }), _jsx("span", { onClick: downloadTermsAndConditions, className: 'ms-1', style: { cursor: 'pointer', textDecoration: 'underline' }, children: translate('debtor.termsAndConditions') })] }), _jsx(FqValidation, { fieldError: errors.termsAgreed })] }), _jsxs("div", { className: 'argeement__uplode__btn', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: () => dispatch(toggleSignAgreementModal()), children: translate('common.cancel') }), _jsx(FqButton, { mode: 'primary', type: 'submit', isDisabled: status === APIStatus.PENDING, children: translate('common.save') })] })] }));
};
export default SignAgreementForm;
