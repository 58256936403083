import { useState } from 'react';
export function useDebounce(func, delay = 1000) {
    const [timer, setTimer] = useState();
    const debouncedFunction = ((...args) => {
        const newTimer = setTimeout(() => {
            func(...args);
        }, delay);
        clearTimeout(timer);
        setTimer(newTimer);
    });
    return debouncedFunction;
}
