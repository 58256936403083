import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getSupportsAsync, createSupportAsync } from './supportThunks';
const initialState = {
    status: APIStatus.IDLE,
    createModalOpen: false
};
export const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSupportAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(createSupportAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.supports?.push(action.payload);
        })
            .addCase(createSupportAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getSupportsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getSupportsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.supports = action.payload;
        })
            .addCase(getSupportsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default supportSlice.reducer;
