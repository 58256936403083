import { getErrorPayload } from '@application/common/helpers/api-helper';
import DebtorApi from '@infrastructure/debtor-api';
import FileApi from '@infrastructure/file-api';
import InvoiceApi from '@infrastructure/invoice-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toggleCreateInvoiceModal, toggleUpdateInvoiceStatusModal } from './invoiceSlice';
import { addSuccessNotification } from '../common/notificationSlice';
import { getOutstandingInvoicesAsync } from '../invoicesByStatus/invoicesByStatusThunk';
import { InvoiceStatus } from '@application/common/enums/invoice-status';
import { translate } from '@application/common/helpers/translate';
import { getCurrentUserDetailsAsync } from '../users/userThunks';
import { setButtonState } from '../common/buttonStateSlice';
export const createInvoiceAsync = createAsyncThunk('invoice/create', async (invoice, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await InvoiceApi.createInvoice(invoice);
        dispatch(toggleCreateInvoiceModal());
        dispatch(addSuccessNotification(translate('invoice.invoiceAddedSuccessfully')));
        const { freelancerInvoicesRequest } = getState().invoice;
        dispatch(getUserInvoicesAsync(freelancerInvoicesRequest));
        dispatch(getCurrentUserDetailsAsync());
        return fulfillWithValue(response.data);
    }
    catch (error) {
        dispatch(setButtonState(false));
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateInvoiceStatusAsync = createAsyncThunk('invoice/updateStatus', async (invoiceStatus, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await InvoiceApi.updateInvoiceStatus(invoiceStatus);
        dispatch(toggleUpdateInvoiceStatusModal());
        if (invoiceStatus.status === InvoiceStatus.Approved)
            dispatch(addSuccessNotification(translate('invoice.invoiceApprovedSuccessfully')));
        else if (invoiceStatus.status === InvoiceStatus.Disapproved)
            dispatch(addSuccessNotification(translate('invoice.invoiceRejectedSuccessfully')));
        else if (invoiceStatus.status === InvoiceStatus.Received)
            dispatch(addSuccessNotification(translate('invoice.invoiceReceivedSuccessfully')));
        const { outstandingInvoicesRequest } = getState().invoiceByStatus;
        dispatch(getOutstandingInvoicesAsync({ ...outstandingInvoicesRequest, pageNumber: 1 }));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserInvoicesAsync = createAsyncThunk('invoices/get', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getUserInvoices(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserInvoicesByStatusAsync = createAsyncThunk('invoices/getInvoicesByStatus', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getUserInvoicesByStatus(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserPaidInvoicesAsync = createAsyncThunk('invoices/getUserPaidInvoices', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getUserPaidInvoices(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getInvoiceByIdAsync = createAsyncThunk('invoices/getByInvoiceId', async (invoiceId, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getInvoiceById(invoiceId);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadInvoiceDocumentAsync = createAsyncThunk('invoices/downloadInvoice', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserDebtorsAsync = createAsyncThunk('invoices/getUserDebtors', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getUserDebtors();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUsersAcceptedDebtorsAsync = createAsyncThunk('invoices/getUsersAcceptedDebtors', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getUsersAcceptedDebtors();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getFreelancerChecklistAsync = createAsyncThunk('invoices/getFreelancerChecklist', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoiceApi.getFrelancerInvoicesChecklist();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadInvoiceDocumentsAsync = createAsyncThunk('invoices/downloadinvoiceDocument', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
