import { translate } from '../helpers/translate';
export var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["Pending"] = 0] = "Pending";
    UserStatus[UserStatus["Approved"] = 1] = "Approved";
    UserStatus[UserStatus["Disapproved"] = 2] = "Disapproved";
    UserStatus[UserStatus["PendingKyc"] = 3] = "PendingKyc";
})(UserStatus || (UserStatus = {}));
export const UserStatusMap = new Map([
    [UserStatus.Pending, translate('enums.userStatus.pending')],
    [UserStatus.Approved, translate('enums.userStatus.approved')],
    [UserStatus.Disapproved, translate('enums.userStatus.disapproved')],
    [UserStatus.PendingKyc, translate('enums.userStatus.pendingKyc')]
]);
