import { faqtoringApi } from './faqtoring-api';
const login = (credentials) => {
    return faqtoringApi.post('account/authenticate', credentials);
};
const getRefreshToken = (refreshToken) => {
    return faqtoringApi.post('token/refresh', refreshToken);
};
const verifyToken = (refreshToken) => {
    return faqtoringApi.post('token/verify-token', refreshToken);
};
const signUp = (user) => {
    return faqtoringApi.post('account/register', user);
};
const confirmEmail = (confirmation) => {
    return faqtoringApi.post('account/email-confirmation', confirmation);
};
const forgotPassword = (input) => {
    return faqtoringApi.post('account/forgot-password', input);
};
const resetPassword = (input) => {
    return faqtoringApi.post('account/reset-password', input);
};
const AuthApi = {
    login,
    getRefreshToken,
    signUp,
    confirmEmail,
    forgotPassword,
    resetPassword,
    verifyToken
};
export default AuthApi;
