import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { translate } from '@application/common/helpers/translate';
import { formatDMY } from '@infrastructure/helpers';
const UserOutstandingInvoices = ({ invoices, status }) => {
    return (_jsx(_Fragment, { children: _jsx("div", { className: 'invoices__table', children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: translate('invoice.debtor') }), _jsx("th", { children: translate('invoice.totalAmount') }), _jsxs("th", { children: [translate('common.faqtoring'), " %"] }), _jsx("th", { children: translate('invoice.dueDate') }), _jsx("th", { children: translate('invoice.remainingHours') })] }) }), invoices && invoices.length > 0 && (_jsx("tbody", { children: invoices.map((invoice) => {
                            return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.debtor.companyName }) }), _jsx("td", { children: _jsxs("div", { className: 'mt-2', children: ["\u20AC", invoice.invoiceAmount.toLocaleString('nl', {
                                                    maximumFractionDigits: 2
                                                })] }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: (invoice.paymentPackageFee + invoice.invoicePackageFee).toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            }) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: formatDMY(invoice.dueDate) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.remainingHours }) })] }, invoice.id));
                        }) })), status === APIStatus.IDLE && invoices && !invoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 5, children: translate('common.noRecordFound') }) }) }))] }) }) }));
};
export default UserOutstandingInvoices;
