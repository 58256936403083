import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
const LanguagesDropDown = () => {
    const [languageDropDownShown] = useState(false);
    const { i18n } = useTranslation();
    const selectedLanguage = i18n.language;
    const onLanguageChange = () => {
        i18n.changeLanguage(selectedLanguage === 'en' ? 'nl' : 'en');
        window.location.reload();
    };
    const languageImagePath = ['en', 'en-US'].includes(selectedLanguage) ? 'images/flage-2.png' : 'images/flage.png';
    return (_jsx("div", { className: 'flage', style: { paddingRight: 20 }, children: _jsx("button", { className: `btn btn-link language__open ${languageDropDownShown ? 'open' : ''}`, onClick: () => onLanguageChange(), children: _jsx("img", { src: languageImagePath, alt: 'selected language' }) }) }));
};
export default LanguagesDropDown;
