import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateUserBalanceAsync } from '@application/features/adminUsers/adminUserThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './style.css';
import FqValidation from '@ui/components/FqValidation';
import { toggleUserOutStandingBalanceModal } from '@application/features/adminUsers/adminUserSlice';
import { APIStatus } from '@application/common/enums/api-status';
import { NumericFormat } from 'react-number-format';
const UserOutStandingBalance = ({ userId }) => {
    const dispatch = useAppDispatch();
    const { balance: balanceDto, status } = useAppSelector((state) => state.user);
    const { setValue, handleSubmit, register, watch, control, formState: { errors } } = useForm({
        defaultValues: { outstandingBalance: 0, newBalance: 0, reason: '' }
    });
    const adjustedBalanceValue = watch('adjustedBalance');
    const outstandingBalanceValue = watch('outstandingBalance');
    useEffect(() => {
        setValue('outstandingBalance', balanceDto?.outstandingBalance ?? 0);
    }, [balanceDto]);
    useEffect(() => {
        setValue('newBalance', Number(outstandingBalanceValue) + Number(adjustedBalanceValue));
    }, [adjustedBalanceValue]);
    const onSubmit = (updateBalanceDto) => {
        updateBalanceDto.freelancerId = userId ?? '';
        dispatch(updateUserBalanceAsync(updateBalanceDto));
    };
    return (_jsx("div", { className: 'view__popup', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), noValidate: true, children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: "Current Balance " }), _jsx(Controller, { name: 'outstandingBalance', control: control, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { disabled: true, className: 'disabled', value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                                onChange(values.floatValue);
                                            } })) })] }) }), _jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: "Add Balance" }), _jsx(Controller, { name: 'adjustedBalance', control: control, rules: {
                                            required: {
                                                value: true,
                                                message: 'This field is required.'
                                            }
                                        }, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                                onChange(values.floatValue);
                                            } })) }), _jsx(FqValidation, { fieldError: errors.adjustedBalance })] }) }), _jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: "New Balance" }), _jsx(Controller, { name: 'newBalance', control: control, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { disabled: true, className: 'disabled', value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                                onChange(values.floatValue);
                                            } })) })] }) }), _jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: "Reason" }), _jsx("textarea", { cols: 34, id: 'reason', ...register('reason', {
                                            required: {
                                                value: true,
                                                message: 'This field is required.'
                                            }
                                        }) })] }) })] }), _jsxs("div", { className: 'debtor__btn', children: [_jsx(FqButton, { title: 'Cancel', type: 'button', customClassName: 'mr-5', onClick: () => dispatch(toggleUserOutStandingBalanceModal()), mode: 'danger', children: "Cancel" }), _jsx(FqButton, { title: 'Save', type: 'submit', mode: 'primary', isDisabled: status === APIStatus.PENDING, children: "Save" })] })] }) }));
};
export default UserOutStandingBalance;
