import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getShufftiProStatusAsync, verifyUserAsync } from './ShufftiProThunks';
const initialState = {
    status: APIStatus.IDLE,
};
export const shufftiProSlice = createSlice({
    name: 'shufftiPro',
    initialState,
    reducers: {
        setVerifyUserRequest(state, action) {
            state.userVerifyRequest = action.payload;
        },
        setShufftiProStatus(state, action) {
            state.shufftiProStatusRequestDto = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(verifyUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.shufftiProRedirectData = action.payload;
        })
            .addCase(verifyUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getShufftiProStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getShufftiProStatusAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.shufftiProStatusDto = action.payload;
        })
            .addCase(getShufftiProStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setVerifyUserRequest, setShufftiProStatus } = shufftiProSlice.actions;
export default shufftiProSlice.reducer;
