import { getErrorPayload } from "@application/common/helpers/api-helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import InvoicePackageApi from '@infrastructure/invoice-package-api';
export const getFreelancerInvoicePackagesAsync = createAsyncThunk('invoicePackage/getForFreelancer', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await InvoicePackageApi.getFreelancerInvoicePackages();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
