import { faqtoringApi } from './faqtoring-api';
import { convertModelToFormData } from './helpers';
const updateFreelancerAccount = async (freelancer) => {
    const formData = convertModelToFormData(freelancer);
    return faqtoringApi.postForm('users', formData);
};
const getFreelancerAccount = () => {
    return faqtoringApi.get('users/profile');
};
const getFreelancerBalance = (userId) => {
    return faqtoringApi.get(`freelancer/GetFreelancerBalance/${userId}`);
};
const updateFreelancerBalance = (request) => {
    const obj = { adjustedBalance: request.adjustedBalance, reason: request.reason, freelancerId: request.freelancerId };
    return faqtoringApi.post(`freelancer/UpdateFreelancerBalance`, obj);
};
const FreelancerApi = {
    updateFreelancerAccount,
    getFreelancerAccount,
    getFreelancerBalance,
    updateFreelancerBalance
};
export default FreelancerApi;
