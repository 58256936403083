import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isDisabled: false,
    status: APIStatus.IDLE
};
export const buttonStateSlice = createSlice({
    name: 'buttonState',
    initialState,
    reducers: {
        setButtonState(state, action) {
            state.isDisabled = action.payload;
        },
    },
    extraReducers: (builder) => {
    }
});
export const { setButtonState } = buttonStateSlice.actions;
export default buttonStateSlice.reducer;
