import { faqtoringApi } from './faqtoring-api';
const getCurrentUserDetails = () => {
    return faqtoringApi.get('freelancer/user-detail');
};
const getUsers = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`users?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getUserById = (userId) => {
    return faqtoringApi.get(`users/${userId}/profile`);
};
const updateUser = (userId, user) => {
    user.status = Number(user.status);
    return faqtoringApi.put(`users/${userId}/update`, user);
};
const disableUser = (userId) => {
    return faqtoringApi.put(`account/disable/${userId}`);
};
const enableUser = (userId) => {
    return faqtoringApi.put(`account/enable/${userId}`);
};
const UserApi = {
    getCurrentUserDetails,
    getUsers,
    getUserById,
    updateUser,
    disableUser,
    enableUser
};
export default UserApi;
