import { translate } from '../helpers/translate';
export var DebtorStatus;
(function (DebtorStatus) {
    DebtorStatus[DebtorStatus["PendingContractGeneration"] = 0] = "PendingContractGeneration";
    DebtorStatus[DebtorStatus["PendingSignature"] = 1] = "PendingSignature";
    DebtorStatus[DebtorStatus["Signed"] = 2] = "Signed";
    DebtorStatus[DebtorStatus["Accepted"] = 3] = "Accepted";
    DebtorStatus[DebtorStatus["Rejected"] = 4] = "Rejected";
})(DebtorStatus || (DebtorStatus = {}));
export const DebtorStatusMap = new Map([
    [DebtorStatus.PendingContractGeneration, translate('enums.debtorStatus.pendingContractGeneration')],
    [DebtorStatus.PendingSignature, translate('enums.debtorStatus.pendingSignature')],
    [DebtorStatus.Signed, translate('enums.debtorStatus.signed')],
    [DebtorStatus.Accepted, translate('enums.debtorStatus.accepted')],
    [DebtorStatus.Rejected, translate('enums.debtorStatus.rejected')]
]);
