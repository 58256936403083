import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getFreelancerAsync, updateFreelancerAsync } from './freelancerThunks';
const initialState = {
    status: APIStatus.IDLE,
    freelancer: {},
    kycModalOpen: false,
    cameraModalOpen: false,
    kycResponseModalOpen: false,
    kycErrorResponseModalOpen: false
};
export const freelancerSlice = createSlice({
    name: 'freelancer',
    initialState,
    reducers: {
        toggleKycModal(state, action) {
            state.kycModalOpen = action.payload;
        },
        toggleCameraModal(state) {
            state.cameraModalOpen = !state.cameraModalOpen;
        },
        toggleKycResponseModal(state) {
            state.kycResponseModalOpen = !state.kycResponseModalOpen;
        },
        resetFreelancerAccount(state) {
            state.freelancer = {};
        },
        toggleKycErrorResponseModal(state) {
            state.kycErrorResponseModalOpen = !state.kycErrorResponseModalOpen;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateFreelancerAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateFreelancerAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateFreelancerAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getFreelancerAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFreelancerAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancer = action.payload;
        })
            .addCase(getFreelancerAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { toggleKycModal, toggleCameraModal, toggleKycResponseModal, resetFreelancerAccount, toggleKycErrorResponseModal } = freelancerSlice.actions;
export default freelancerSlice.reducer;
