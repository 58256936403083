import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getDashboardAsync } from './dashboardThunk';
const initialState = {
    status: APIStatus.IDLE
};
export const dashboardStateSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDashboardAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.dashboard = action.payload;
        })
            .addCase(getDashboardAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default dashboardStateSlice.reducer;
