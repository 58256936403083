import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DebtorDocumentType } from '@application/common/enums/debtor-document-type';
import { DebtorStatusMap } from '@application/common/enums/debtor-status';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { translate } from '@application/common/helpers/translate';
import { downloadDebtorContractAsync } from '@application/features/debtors/debtorThunks';
import { useAppDispatch } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import moment from 'moment';
const ActiveDebtors = ({ debtors, viewDebtor }) => {
    const dispatch = useAppDispatch();
    const downloadDebtorContract = (debtorId) => {
        const debtor = debtors && debtors.find((x) => x.debtorId === debtorId);
        if (debtor) {
            const contractDocument = debtor?.debtorDocuments.find((x) => x.type === DebtorDocumentType.DebtorContract);
            const fileDownloadInput = { folderName: contractDocument?.folderName, fileName: contractDocument?.name };
            dispatch(downloadDebtorContractAsync(fileDownloadInput))
                .unwrap()
                .then((res) => {
                const mimeType = getMimeTypeFromBase64(res.file);
                const pdfWindow = window.open();
                pdfWindow?.document.write(`<title>DebtorContract-${debtor.freelanerName}</title>`);
                pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
            });
        }
    };
    const downloadSignedFaqtoringContract = (debtorId) => {
        const debtor = debtors && debtors.find((x) => x.debtorId === debtorId);
        if (debtor) {
            const contractDocument = debtor?.debtorDocuments.find((x) => x.type === DebtorDocumentType.SignedFaqtoringContract);
            const fileDownloadInput = { folderName: contractDocument?.folderName, fileName: contractDocument?.name };
            dispatch(downloadDebtorContractAsync(fileDownloadInput))
                .unwrap()
                .then((res) => {
                const mimeType = getMimeTypeFromBase64(res.file);
                const pdfWindow = window.open();
                pdfWindow?.document.write(`<title> SignedFaqtoringContract-${debtor.freelanerName}</title>`);
                pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
            });
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Company Name" }), _jsx("th", { children: "Freelancer Name" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Date Added" }), _jsx("th", { children: "Debtors Contract" }), _jsx("th", { children: "Faqtoring Contract" }), _jsx("th", {})] }) }), debtors && debtors.length > 0 && (_jsx("tbody", { children: debtors.map((debtor) => {
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: debtor.companyName }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: debtor.freelanerName }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: DebtorStatusMap.get(debtor.status) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: moment(debtor.createdDate).format('D/MM/yyyy') }) }), _jsx("td", { children: _jsx(FqButton, { mode: 'primary', size: 'sm', onClick: () => downloadDebtorContract(debtor.debtorId), children: "Download" }) }), _jsx("td", { children: _jsx(FqButton, { mode: 'primary', size: 'sm', onClick: () => downloadSignedFaqtoringContract(debtor.debtorId), children: "Download" }) }), _jsx("td", { children: _jsx("div", { className: 'd-flex', children: _jsx(FqButton, { mode: 'secondary', size: 'sm', onClick: () => viewDebtor(debtor.debtorId), children: translate('common.view') }) }) })] }, debtor.debtorId));
                    }) })), !!(debtors && debtors.length === 0) && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 12, children: translate('common.noRecordFound') }) }) }))] }) }));
};
export default ActiveDebtors;
