import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { UserStatusMap } from '@application/common/enums/user-status';
import { translate } from '@application/common/helpers/translate';
import { formatDMY } from '@infrastructure/helpers';
import FqButton from '@ui/components/FqButton';
import './style.css';
const UserList = ({ users = [], status, onDetailUser, onDisableUser, onEnableUser }) => {
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Created On" }), _jsx("th", {})] }) }), users && users.length > 0 && (_jsx("tbody", { children: users.map((user) => {
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: user.name }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: UserStatusMap.get(user.status) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: formatDMY(user.createdOn) }) }), _jsx("td", { children: _jsxs("div", { className: 'd-flex justify-content-end', children: [_jsx(FqButton, { mode: 'secondary', size: 'sm', className: 'btn-users', onClick: () => onDetailUser(user.id), children: "View" }), user.isActive ? (_jsx(FqButton, { mode: 'danger', size: 'sm', customClassName: 'ms-2', onClick: () => onDisableUser(user.id), children: "Disable" })) : (_jsx(FqButton, { mode: 'primary', size: 'sm', customClassName: 'ms-2', onClick: () => onEnableUser(user.id), children: "Enable" }))] }) })] }, user.id));
                    }) })), status === APIStatus.IDLE && users && !users.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 4, children: translate('common.noRecordFound') }) }) }))] }) }));
};
export default UserList;
