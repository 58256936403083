export var DebtorDocumentType;
(function (DebtorDocumentType) {
    DebtorDocumentType[DebtorDocumentType["DebtorContract"] = 0] = "DebtorContract";
    DebtorDocumentType[DebtorDocumentType["FaqtoringContract"] = 1] = "FaqtoringContract";
    DebtorDocumentType[DebtorDocumentType["SignedFaqtoringContract"] = 2] = "SignedFaqtoringContract";
})(DebtorDocumentType || (DebtorDocumentType = {}));
export const DebtorDocumentTypeMap = new Map([
    [DebtorDocumentType.DebtorContract, 'Debtor Contract'],
    [DebtorDocumentType.FaqtoringContract, 'Faqtoring Contract'],
    [DebtorDocumentType.SignedFaqtoringContract, 'Signed Faqtoring Contract']
]);
