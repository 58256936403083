import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { AppConsts } from '@application/common/constants/app-consts';
import { InvoiceStatus } from '@application/common/enums/invoice-status';
import { getFreelancerOutstandingInvoicesAsync, getOutstandingInvoicesAsync, getProcessedInvoicesAsync, getReceivedInvoicesAsync } from './invoicesByStatusThunk';
const processedInvoicesRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE
};
const outstandingInvoiceRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
    invoiceStatus: InvoiceStatus.Pending
};
const receivedInvoicesRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
    invoiceStatus: InvoiceStatus.Received
};
const initialState = {
    status: APIStatus.IDLE,
    invoices: [],
    outstandingInvoicesRequest: outstandingInvoiceRequest,
    processedInvoicesRequest: processedInvoicesRequest,
    receivedInvoicesRequest: receivedInvoicesRequest,
    receivedInvoiceModalOpen: false,
    viewProcessedInvoiceModalOpen: false,
    freelancerOutstandingInvoicesRequest: ''
};
export const invoiceByStatusSlice = createSlice({
    name: 'invoicesByStatus',
    initialState,
    reducers: {
        setOutstandingInvoicesRequest(state, action) {
            state.outstandingInvoicesRequest = action.payload;
        },
        setFreelancerOutstandingInvoicesRequest(state, action) {
            state.freelancerOutstandingInvoicesRequest = action.payload;
        },
        setPorcessedInvoicesRequest(state, action) {
            state.processedInvoicesRequest = action.payload;
        },
        setReceivedInvoicesRequest(state, action) {
            state.receivedInvoicesRequest = action.payload;
        },
        toggleReceivedInvoiceModal(state) {
            state.receivedInvoiceModalOpen = !state.receivedInvoiceModalOpen;
        },
        toggleViewProcessedInvoiceModal(state) {
            state.viewProcessedInvoiceModalOpen = !state.viewProcessedInvoiceModalOpen;
        },
        resetOutstandingInvoices(state) {
            state.outstandingInvoices = undefined;
            state.outstandingInvoicesRequest = outstandingInvoiceRequest;
        },
        resetProcessedInvoices(state) {
            state.processedInvoices = undefined;
            state.processedInvoicesRequest = processedInvoicesRequest;
        },
        resetReceivedInvoices(state) {
            state.receivedInvoices = undefined;
            state.receivedInvoicesRequest = receivedInvoicesRequest;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOutstandingInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getOutstandingInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.outstandingInvoices = action.payload;
        })
            .addCase(getOutstandingInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getProcessedInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getProcessedInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.processedInvoices = action.payload;
        })
            .addCase(getProcessedInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getFreelancerOutstandingInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFreelancerOutstandingInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.invoices = action.payload;
        })
            .addCase(getFreelancerOutstandingInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getReceivedInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getReceivedInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.receivedInvoices = action.payload;
        })
            .addCase(getReceivedInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { setOutstandingInvoicesRequest, setPorcessedInvoicesRequest, setReceivedInvoicesRequest, setFreelancerOutstandingInvoicesRequest, toggleReceivedInvoiceModal, toggleViewProcessedInvoiceModal, resetOutstandingInvoices, resetProcessedInvoices, resetReceivedInvoices } = invoiceByStatusSlice.actions;
export default invoiceByStatusSlice.reducer;
