import { toggleApproveFaqtoringContractModal, toggleGenerateFaqtoringContractModal } from '@application/features/debtors/debtorSlice';
import { getErrorPayload } from '@application/common/helpers/api-helper';
import DebtorApi from '@infrastructure/debtor-api';
import FileApi from '@infrastructure/file-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addSuccessNotification } from '../common/notificationSlice';
import { toggleCreateDebtorModal, toggleSignAgreementModal } from './debtorSlice';
import { translate } from '@application/common/helpers/translate';
import { setButtonState } from '../common/buttonStateSlice';
export const createDebtorAsync = createAsyncThunk('debtor/create', async (debtor, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await DebtorApi.createDebtor(debtor);
        dispatch(toggleCreateDebtorModal());
        dispatch(addSuccessNotification(translate('debtor.debtorAddedSuccessfully')));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        dispatch(setButtonState(false));
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getUserDebtorsAsync = createAsyncThunk('debtor/getUserDebtors', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getUserDebtors();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getActiveDebtorsAsync = createAsyncThunk('debtor/getActiveDebtors', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getActiveDebtors(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getInactiveDebtorsAsync = createAsyncThunk('debtor/getInactiveDebtors', async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getInactiveDebtors(request);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadDebtorContractAsync = createAsyncThunk('debtor/downloadContract', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadTermsAndConditionsAsync = createAsyncThunk('debtor/downloadTermsAndConditions', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const uploadSignedContractAsync = createAsyncThunk('debtor/uploadSignedContract', async (singedContract, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await DebtorApi.uploadSignedFaqtroignContract(singedContract);
        dispatch(toggleSignAgreementModal());
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateDebtorStatusAsync = createAsyncThunk('debtor/updateDebtorStatus', async (status, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await DebtorApi.updateDebtorStatus(status);
        dispatch(toggleApproveFaqtoringContractModal());
        const { activeDebtorsRequest, inactiveDebtorsRequest } = getState().debtor;
        dispatch(getActiveDebtorsAsync(activeDebtorsRequest));
        dispatch(getInactiveDebtorsAsync(inactiveDebtorsRequest));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const generateFaqtoringContractAsync = createAsyncThunk('debtor/GenrateFactoringContract', async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await DebtorApi.genrateFactoringContract(request);
        dispatch(toggleGenerateFaqtoringContractModal());
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const updateGenratedFactoringContractStatusAsync = createAsyncThunk('debtor/updateGenratedFactoringContractStatus', async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await DebtorApi.updateGenratedFactoringContractStatus(request);
        dispatch(toggleGenerateFaqtoringContractModal());
        if (request.isApproved) {
            dispatch(addSuccessNotification(translate('debtor.faqtoringContractGeneratedSuccessfully')));
        }
        else {
            dispatch(addSuccessNotification(translate('debtor.faqtoringContractGenerationCanceled')));
        }
        const { activeDebtorsRequest, inactiveDebtorsRequest } = getState().debtor;
        dispatch(getActiveDebtorsAsync(activeDebtorsRequest));
        dispatch(getInactiveDebtorsAsync(inactiveDebtorsRequest));
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getDebtorByIdAsync = createAsyncThunk('debtor/getDebtorById', async (debtorId, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await DebtorApi.getDebtorById(debtorId);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadDebtorDocumentAsync = createAsyncThunk('invoices/downloadDebtor', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
