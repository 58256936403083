import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch } from '@application/store/useStore';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import { getFaqsAsync } from '@application/features/faqs/faqThunks';
import { useEffect } from 'react';
import CreateSupport from './CreateSupport';
import FaqList from './Faqs';
import { translate } from '@application/common/helpers/translate';
const Contact = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getFaqsAsync());
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: translate('support.contactUs') }), _jsx(CreateSupport, {}), _jsx(FaqList, {})] }));
};
export default Contact;
