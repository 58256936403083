import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { filterFreelancerDebtors, toggleCreateDebtorModal, toggleSignAgreementModal, toggleViewDebtorModal } from '@application/features/debtors/debtorSlice';
import { getDebtorByIdAsync, getUserDebtorsAsync } from '@application/features/debtors/debtorThunks';
import { toggleCreateInvoiceModal } from '@application/features/invoices/invoiceSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import { useEffect, useState } from 'react';
import CreateInvoiceForm from '../invoices/CreateInvoiceForm';
import CreateDebtorForm from './CreateDebtorForm';
import DebtorsList from './DebtorsList';
import SignAgreementForm from './SignAgreementForm';
import { setButtonState } from '@application/features/common/buttonStateSlice';
import ViewDebtorForm from '@ui/containers/admin/debtors/ViewDebtorForm';
const Debtors = () => {
    const [debtorId, setDebtorId] = useState();
    const dispatch = useAppDispatch();
    const { createDebtorModalOpen, signAgreementModalOpen, filteredFreelancerDebtors, status, viewDebtorModalOpen, debtor } = useAppSelector((state) => state.debtor);
    const { createInvoiceModalOpen: createInvoiceModelOpen } = useAppSelector((state) => state.invoice);
    useEffect(() => {
        dispatch(getUserDebtorsAsync());
    }, [dispatch]);
    const handleSearch = (e) => {
        dispatch(filterFreelancerDebtors(e.target.value));
    };
    const onAddInvoice = (debtorId) => {
        dispatch(toggleCreateInvoiceModal());
        setDebtorId(debtorId);
    };
    const onSignAgreement = (debtorId) => {
        dispatch(toggleSignAgreementModal());
        setDebtorId(debtorId);
    };
    const toggleDebtorCreateModal = () => {
        dispatch(toggleCreateDebtorModal());
        dispatch(setButtonState(false));
    };
    const viewDebtor = (debtorId) => {
        dispatch(getDebtorByIdAsync(debtorId));
        dispatch(toggleViewDebtorModal());
    };
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: translate('debtor.myDebtors'), showAddButton: true, onAdd: toggleDebtorCreateModal }), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: translate('common.overview'), showSearch: true, onSearch: handleSearch }), children: _jsx(DebtorsList, { filteredDebtors: filteredFreelancerDebtors, status: status, onAddInvoice: onAddInvoice, onSignAgreement: onSignAgreement, viewDebtor: viewDebtor }) }), _jsx(FqDialog, { open: createDebtorModalOpen, maxWidth: 800, title: translate('debtor.addDebtor'), onClose: () => dispatch(toggleCreateDebtorModal()), children: _jsx(CreateDebtorForm, {}) }), _jsx(FqDialog, { open: createInvoiceModelOpen, maxWidth: 850, title: translate('invoice.addInvoice'), onClose: () => dispatch(toggleCreateInvoiceModal()), children: _jsx(CreateInvoiceForm, { debtorsId: debtorId }) }), _jsx(FqDialog, { open: signAgreementModalOpen, maxWidth: 560, title: translate('debtor.signFaqtoringAgreement'), onClose: () => dispatch(toggleSignAgreementModal()), children: _jsx(SignAgreementForm, { debtorId: debtorId }) }), _jsx(FqDialog, { open: viewDebtorModalOpen, maxWidth: 800, title: translate('debtor.viewDebtor'), onClose: () => dispatch(toggleViewDebtorModal()), children: _jsx(ViewDebtorForm, { debtor: debtor }) })] }));
};
export default Debtors;
