import { getErrorPayload } from '@application/common/helpers/api-helper';
import { translate } from '@application/common/helpers/translate';
import SupportService from '@infrastructure/support-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addSuccessNotification } from '../common/notificationSlice';
export const getSupportsAsync = createAsyncThunk('support/getAll', async (_, { rejectWithValue }) => {
    try {
        const response = await SupportService.getSupportMessages();
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const createSupportAsync = createAsyncThunk('support/create', async (support, { rejectWithValue, dispatch }) => {
    try {
        const response = await SupportService.sendSupportMessage(support);
        dispatch(addSuccessNotification(translate('support.supportMessageSent')));
        return response.data;
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
