import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import AppRoutes from '@ui/containers/layout/AppRoutes';
import Loading from '@ui/components/FqLoader';
import '@ui/styles/bootstrap.css';
import '@ui/styles/style.css';
import '@ui/styles/responsive-style.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { getCurrentUserDetailsAsync } from '@application/features/users/userThunks';
import i18n from './i18n';
import moment from 'moment';
import { logout } from '@application/features/auth/authSlice';
import { useLocation } from 'react-router-dom';
import { resetStateByPathName } from '@application/common/helpers/state-helper';
const App = () => {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector((state) => state.loading);
    const { currentUser } = useAppSelector((state) => state.auth);
    const location = useLocation();
    useEffect(() => {
        if (currentUser && dispatch) {
            if (currentUser.role === 'Freelancer')
                dispatch(getCurrentUserDetailsAsync());
        }
    }, [currentUser, dispatch]);
    useEffect(() => {
        moment.locale(i18n.language);
    }, []);
    useEffect(() => {
        const onStorageChange = (event) => {
            if (event.key === 'AUTH_TOKEN' && event.newValue === null)
                dispatch(logout());
        };
        window.addEventListener('storage', onStorageChange);
        return () => {
            window.removeEventListener('storage', onStorageChange);
        };
    }, [dispatch]);
    useEffect(() => {
        return () => {
            resetStateByPathName(location.pathname);
        };
    }, [location.pathname]);
    return (_jsxs(_Fragment, { children: [_jsx(ToastContainer, { position: 'top-right', autoClose: 5000, newestOnTop: true, pauseOnHover: true, theme: 'colored' }), isLoading && _jsx(Loading, { isLoading: isLoading }), _jsx("div", { className: 'boxed_wrapper', children: _jsx(AppRoutes, {}) })] }));
};
export default App;
