import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { translate } from '@application/common/helpers/translate';
import { formatDMY } from '@infrastructure/helpers';
import FqButton from '@ui/components/FqButton';
const ReceivedInvoicesList = ({ invoices = [], status, viewProcessedInvoice }) => {
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "ID" }), _jsx("th", { children: "Freelancer Name" }), _jsx("th", { children: "Invoice Amount" }), _jsx("th", { children: "Received Date" }), _jsx("th", {})] }) }), invoices && invoices.length > 0 && (_jsx("tbody", { children: invoices.map((invoice) => {
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.invoiceNo }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.freelancerName }) }), _jsx("td", { children: _jsxs("div", { className: 'mt-2', children: ["\u20AC", invoice.invoiceAmountIncludingVAT.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            })] }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: formatDMY(invoice.receivedDate) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2 text-center', children: _jsx(FqButton, { mode: 'primary', size: 'sm', onClick: () => viewProcessedInvoice(invoice.id), children: "View" }) }) })] }, invoice.id));
                    }) })), status === APIStatus.IDLE && invoices && !invoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 7, children: translate('common.noRecordFound') }) }) }))] }) }));
};
export default ReceivedInvoicesList;
