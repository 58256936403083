import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { toggleCreateDebtorModal } from '@application/features/debtors/debtorSlice';
import { createDebtorAsync } from '@application/features/debtors/debtorThunks';
import { getFreelancerPaymentPackagesAsync } from '@application/features/paymentPackages/paymentPackageThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatYMD } from '@infrastructure/helpers';
import FqButton from '@ui/components/FqButton';
import FqTooltip from '@ui/components/FqToolTip';
import FqValidation from '@ui/components/FqValidation';
import { selectStyles } from '@ui/styles/select-style';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { APIStatus } from '@application/common/enums/api-status';
const CreateDebtorForm = () => {
    const dispatch = useAppDispatch();
    const { freelancerPaymentPackages } = useAppSelector((state) => state.paymentPackage);
    const { status } = useAppSelector((state) => state.debtor);
    const [contractStartDate, setContractStartDate] = useState();
    const [contractEndDate, setContractEndDate] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const { handleSubmit, register, watch, setValue, control, clearErrors, setError, formState: { errors } } = useForm();
    const validateContractEndDate = (contractEndDate) => {
        if (new Date(watch('contractStartDate')) >= new Date(contractEndDate)) {
            return translate('validations.shouldBeGreatherThanContractStartDate');
        }
    };
    const uploadedFile = watch('debtorContract');
    const uploadFile = (e) => {
        if (e.target.files && e.target.files.length) {
            const uploadedFile = e.target.files[0];
            if (!['image/jpeg', 'application/pdf'].includes(uploadedFile.type))
                setError('debtorContract', {
                    message: translate('validations.filesNotAllowed')
                });
            else {
                setValue('debtorContract', uploadedFile);
                clearErrors('debtorContract');
            }
        }
        else {
            setValue('debtorContract', undefined);
            clearErrors('debtorContract');
        }
    };
    const onSubmit = (debtor) => {
        debtor.isIndefiniteContractEndDate = isChecked;
        debtor.contractEndDate = debtor.contractEndDate && isChecked ? undefined : debtor.contractEndDate;
        dispatch(createDebtorAsync(debtor));
    };
    useEffect(() => {
        dispatch(getFreelancerPaymentPackagesAsync());
    }, [dispatch]);
    const setContractStartValue = (date) => {
        date === null || date === undefined
            ? setError('contractStartDate', { type: 'required', message: translate('validations.fieldIsRequired') })
            : setError('contractStartDate', { type: undefined, message: undefined });
        setContractStartDate(date !== null ? moment(date).toDate() : null);
        setValue('contractStartDate', date !== null ? formatYMD(date) : '');
    };
    const setContractEndValue = (date) => {
        date === null || date === undefined
            ? setError('contractEndDate', { type: 'required', message: translate('validations.fieldIsRequired') })
            : setError('contractEndDate', { type: undefined, message: undefined });
        setContractEndDate(date !== null ? moment(date).toDate() : null);
        setValue('contractEndDate', date !== null ? formatYMD(date) : '');
    };
    const handleToggle = () => {
        setIsChecked(!isChecked);
        setContractEndDate(null);
        setError('contractEndDate', { type: undefined, message: undefined });
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.companyName') }), _jsx("input", { type: 'text', maxLength: 20, ...register('companyName', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.companyName })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.streetAddress') }), _jsx("input", { type: 'text', maxLength: 100, ...register('companyAddress', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.companyAddress })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.companyCountry') }), _jsx("input", { type: 'text', maxLength: 100, ...register('companyCountry', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.companyCountry })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.zipCode') }), _jsx("input", { type: 'text', maxLength: 10, ...register('zipCode', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: translate('validations.maximumCharactersAllowed', { count: 10 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.zipCode })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.cci') }), _jsx("input", { type: 'text', maxLength: 20, ...register('cci', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.cci })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.vatNumber') }), _jsx("input", { type: 'text', maxLength: 20, ...register('vat', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.vat })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.debtorEmailAddress') }), _jsx("input", { type: 'text', maxLength: 100, ...register('email', {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: translate('validations.invalidEmailAddress')
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                        }
                                    }) }), _jsx(FqValidation, { fieldError: errors.email })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.contractStartDate') }), _jsx(Controller, { name: 'contractStartDate', control: control, rules: {
                                        required: {
                                            value: true,
                                            message: translate('validations.fieldIsRequired')
                                        }
                                    }, render: () => (_jsx(DatePicker, { peekNextMonth: true, showPopperArrow: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: 'select', placeholderText: 'dd/mm/yyyy', dateFormat: 'dd/MM/yyyy', selected: contractStartDate, onChange: setContractStartValue })) }), _jsx(FqValidation, { fieldError: errors.contractStartDate })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { className: "form-check-label", htmlFor: "flexSwitchCheckDefault", children: translate('debtor.isIndefiniteContractEndDate') }), _jsx("div", { className: "form-check form-switch togglePlacement", children: _jsx("input", { className: "form-check-input", type: "checkbox", id: "flexSwitchCheckDefault", checked: isChecked, onChange: handleToggle, style: { height: 36, width: 66 } }) })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('debtor.contractEndDate') }), _jsx(Controller, { name: 'contractEndDate', control: control, rules: {
                                        required: !isChecked ? { message: translate('validations.fieldIsRequired'), value: true } : false,
                                        validate: (value) => validateContractEndDate(value)
                                    }, render: ({ field: { onChange, value } }) => (_jsx(DatePicker, { peekNextMonth: true, showPopperArrow: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: 'select', placeholderText: 'dd/mm/yyyy', dateFormat: 'dd/MM/yyyy', selected: contractEndDate, onChange: setContractEndValue, disabled: isChecked ? true : false })) }), _jsx(FqValidation, { fieldError: errors.contractEndDate })] }) })] }), _jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsxs("label", { children: [translate('debtor.paymentPeriod'), _jsx(FqTooltip, { content: translate('debtor.paymentTermInfo') })] }), _jsx(Controller, { name: 'paymentPackageId', control: control, rules: {
                                    required: {
                                        value: true,
                                        message: translate('validations.fieldIsRequired')
                                    }
                                }, render: ({ field: { onChange, value } }) => (_jsx(Select, { placeholder: translate('debtor.selectPaymentPeriod'), isSearchable: false, isClearable: true, styles: selectStyles, value: freelancerPaymentPackages.find((c) => c.value === value), options: freelancerPaymentPackages, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.paymentPackageId })] }) }) }), _jsxs("div", { className: 'form-group debtorFileUpload', children: [_jsx("label", { className: 'file-upload-label align-self-center', htmlFor: 'file-upload', children: _jsxs("div", { className: 'd-flex', children: [_jsx("div", { className: 'file-upload-icon', children: _jsx(FontAwesomeIcon, { icon: 'cloud-arrow-up', fontSize: '1.2em', color: '#FF0000' }) }), _jsx("h3", { className: 'align-self-center ms-2', children: translate('debtor.uploadDebtorContract') }), _jsx("span", { className: 'toolTipPlacement', children: _jsx(FqTooltip, { content: translate('debtor.uploadDebtorContractTooltip') }) })] }) }), _jsx(Controller, { name: 'debtorContract', control: control, rules: {
                            required: {
                                message: translate('validations.fieldIsRequired'),
                                value: true
                            }
                        }, render: () => _jsx("input", { accept: 'image/jpeg,application/pdf', type: 'file', id: 'file-upload', hidden: true, onChange: uploadFile }) }), uploadedFile && _jsx("div", { className: 'uploaded-file', children: uploadedFile?.name }), _jsx(FqValidation, { fieldError: errors.debtorContract })] }), _jsxs("div", { className: 'd-flex justify-content-end', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: () => dispatch(toggleCreateDebtorModal()), children: translate('common.cancel') }), _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: translate('common.save') })] })] }));
};
export default CreateDebtorForm;
