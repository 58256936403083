import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch } from '@application/store/useStore';
import { useEffect } from 'react';
import FqButton from '@ui/components/FqButton';
import { logout } from '@application/features/auth/authSlice';
import { translate } from '@application/common/helpers/translate';
import { toggleKycErrorResponseModal } from '@application/features/freelancer/freelancerSlice';
const KycErrorResponse = ({ userId }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
    }, [dispatch]);
    return (_jsx("form", { className: 'invoice__form', children: _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-md-12', children: _jsx("h3", { className: 'text-center', children: translate('kyc.failedTitle') }) }), _jsx("div", { className: 'col-md-12 pt-4', children: _jsx("p", { className: 'text-center', children: translate('kyc.failedMessage') }) }), _jsx("div", { className: 'col-md-12 pt-4', children: _jsx("p", { className: 'text-center', children: _jsx(FqButton, { mode: 'primary', type: 'button', customClassName: 'ms-2', onClick: () => {
                                dispatch(toggleKycErrorResponseModal());
                                dispatch(logout());
                            }, children: translate('menu.signOut') }) }) })] }) }));
};
export default KycErrorResponse;
