import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import FqButton from '@ui/components/FqButton';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { downloadDebtorContractAsync, updateGenratedFactoringContractStatusAsync } from '@application/features/debtors/debtorThunks';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { useState } from 'react';
import FqValidation from '@ui/components/FqValidation';
import { Language } from '@application/common/enums/language';
import { APIStatus } from '@application/common/enums/api-status';
import { NumericFormat } from 'react-number-format';
const GenerateContractForm = ({ debtorId, faqtoringContractList, onFaqtoringContractGenrated }) => {
    const [contractDeclined, setContractDeclined] = useState(false);
    const { status } = useAppSelector((state) => state.debtor);
    const dispatch = useAppDispatch();
    const { setValue, handleSubmit, register, clearErrors, control, formState: { errors } } = useForm({
        defaultValues: {
            debtorId: debtorId
        }
    });
    const onSubmit = (request) => {
        request.debtorId = debtorId;
        request.isApproved = !contractDeclined;
        dispatch(updateGenratedFactoringContractStatusAsync(request))
            .unwrap()
            .then((res) => {
            onFaqtoringContractGenrated(debtorId);
        });
    };
    const cancelContractDecline = () => {
        setValue('reason', '');
        clearErrors('reason');
        setContractDeclined(false);
    };
    const downloadFaqtoringContract = (folderName, fileName) => {
        const fileDownloadInput = { folderName: folderName, fileName: fileName };
        dispatch(downloadDebtorContractAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>FaqtoringContract</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx("div", { className: 'row', children: _jsx("div", { className: 'text-center', children: _jsx("div", { className: ' account__title', children: faqtoringContractList.map((contract, index) => {
                            if (contract.language === Language.EN) {
                                return (_jsx("div", { children: _jsxs("a", { className: 'contract-download-link contact__page', style: { fontSize: 20 }, onClick: () => downloadFaqtoringContract(contract.folderName, contract.name), children: ["View The English Contract ", '>'] }) }, index));
                            }
                            else {
                                return (_jsx("div", { children: _jsxs("a", { className: 'contract-download-link contact__page', style: { fontSize: 20 }, onClick: () => downloadFaqtoringContract(contract.folderName, contract.name), children: ["View The Dutch Contract ", '>'] }) }, index));
                            }
                        }) }) }) }), _jsx("div", { className: "row d-flex justify-content-center mt-3", children: _jsx("div", { className: "col-lg-8 col-sm-12", children: _jsxs("div", { className: "row align-items-center", children: [_jsx("div", { className: "col-lg-4 col-sm-12 text-end", children: _jsx("label", { htmlFor: "fixedFee", style: { fontSize: 18 }, className: 'mt-2', children: "Fee:" }) }), _jsx("div", { className: "col-lg-6 col-sm-12", children: _jsx(Controller, { name: 'fixedFee', control: control, rules: {
                                        required: !contractDeclined ? { message: 'This field is required.', value: true } : false,
                                        validate: !contractDeclined ? (value) => value >= 0 || 'Negative value is not allowed.' : undefined
                                    }, render: ({ field: { onChange, value } }) => (_jsx(NumericFormat, { value: value, thousandSeparator: '.', decimalSeparator: ',', onValueChange: (values) => {
                                            onChange(values.floatValue);
                                        }, style: { height: 20 } })) }) }), _jsx("div", { className: 'row d-flex justify-content-end ps-4', children: _jsx("div", { className: 'col-lg-8 col-sm-12', children: _jsx(FqValidation, { fieldError: errors.fixedFee }) }) })] }) }) }), _jsxs("div", { className: 'text-center p-3', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: () => setContractDeclined(true), children: "Decline Contract" }), _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: contractDeclined || status === APIStatus.PENDING, children: "Confirm" })] }), contractDeclined && (_jsxs("div", { className: 'mt-2', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { children: "Reason" }), _jsx("textarea", { style: { height: 150, width: '100%', borderRadius: '3px' }, ...register('reason', {
                                    validate: {
                                        required: (value) => {
                                            if (!value && contractDeclined)
                                                return 'This field is required.';
                                        }
                                    }
                                }) }), _jsx(FqValidation, { fieldError: errors.reason })] }), _jsxs("div", { className: 'text-end', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: cancelContractDecline, children: "Cancel" }), _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: "Save" })] })] }))] }));
};
export default GenerateContractForm;
