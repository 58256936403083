import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import './style.css';
const FqDialog = ({ onClose, open, title, children, maxWidth = 770, hideCloseIcon }) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [open]);
    return (_jsx("div", { id: 'invoice__popup', className: `invoice__popup ${open && 'popup__visible'}`, children: _jsxs("div", { className: 'popup__inner', children: [_jsx("div", { className: 'overlay__layer' }), _jsxs("div", { className: 'fq-modal-body', style: { maxWidth: maxWidth, maxHeight: '95vh', overflowY: 'auto' }, children: [_jsxs("div", { className: 'd-flex', children: [_jsx("div", { className: 'invoice__crate__title me-auto', children: _jsx("h3", { children: title ?? ' ' }) }), !hideCloseIcon && _jsx(FontAwesomeIcon, { icon: 'times', fontSize: 22, style: { cursor: 'pointer' }, onClick: onClose })] }), !!open && _jsx(_Fragment, { children: children })] })] }) }));
};
export default FqDialog;
