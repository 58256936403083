import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { downloadCOCDocumentAsync, getFreelancerAsync, updateFreelancerAsync } from '@application/features/freelancer/freelancerThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqValidation from '@ui/components/FqValidation';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserStatus, UserStatusMap } from '@application/common/enums/user-status';
import './style.css';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatYMD } from '@infrastructure/helpers';
import { translate } from '@application/common/helpers/translate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getCountriesAsync } from '@application/features/country/countryThunk';
import Select from 'react-select';
import { selectStyles } from '@ui/styles/select-style';
import FqButton from '@ui/components/FqButton';
import { APIStatus } from '@application/common/enums/api-status';
import { toggleKycErrorResponseModal, toggleKycModal, toggleKycResponseModal } from '@application/features/freelancer/freelancerSlice';
import LocalStorageService from '@infrastructure/local-storage-service';
import { getUserTypeAsync } from '@application/features/userType/userTypeThunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { convertToPdfFile } from '@application/common/helpers/file-helper';
const AccountFrom = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { freelancer, status } = useAppSelector((state) => state.freelancer);
    const { countriesDropdown } = useAppSelector((state) => state.country);
    const { userTypesDropDown } = useAppSelector((state) => state.userType);
    const [statusDisabled, setStatusDisabled] = useState(false);
    const [startDate, setStartDate] = useState();
    const { handleSubmit, register, watch, setValue, setError, clearErrors, control, reset, formState: { errors } } = useForm({
        defaultValues: { ...freelancer, identityDocuments: [] }
    });
    useEffect(() => {
        const status = searchParams.get('status');
        if (status === '1') {
            dispatch(getFreelancerAsync());
        }
    }, [dispatch, searchParams]);
    const onSubmit = (updateFreelancer) => {
        const maxFileSize = 1048576;
        updateFreelancer.dateOfBirth = formatYMD(startDate);
        if (freelancer.cocDocument && updateFreelancer.COCDocument === undefined) {
            const fileDto = { folderName: freelancer?.cocDocument.folderName, fileName: freelancer?.cocDocument?.name };
            dispatch(downloadCOCDocumentAsync(fileDto))
                .unwrap()
                .then((res) => {
                const blob = convertToPdfFile(res.file);
                const file = new File([blob], freelancer.cocDocument?.name, { type: 'application/pdf' });
                updateFreelancer.COCDocument = file;
                dispatch(updateFreelancerAsync(updateFreelancer));
            });
        }
        if (updateFreelancer.COCDocument && updateFreelancer.COCDocument.size > maxFileSize) {
            setError('COCDocument', {
                message: translate('validations.maxAllowedFileSize')
            });
        }
        else {
            dispatch(updateFreelancerAsync(updateFreelancer));
        }
    };
    const setDateOfBirth = (date) => {
        date === null || date === undefined
            ? setError('dateOfBirth', { type: 'required', message: translate('validations.fieldIsRequired') })
            : setError('dateOfBirth', { type: undefined, message: undefined });
        setStartDate(date !== null ? moment(date).toDate() : null);
        setValue('dateOfBirth', date !== null ? formatYMD(date) : '');
    };
    useEffect(() => {
        dispatch(getCountriesAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getFreelancerAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getUserTypeAsync());
    }, [dispatch]);
    const uploadedFile = watch('COCDocument');
    const uploadFile = (e) => {
        if (e.target.files && e.target.files.length) {
            const uploadedFile = e.target.files[0];
            if (!['application/pdf'].includes(uploadedFile.type))
                setError('COCDocument', {
                    message: translate('validations.onlyPDFAllowed')
                });
            else {
                setValue('COCDocument', uploadedFile);
                clearErrors('COCDocument');
            }
        }
        else {
            setValue('COCDocument', undefined);
            clearErrors('COCDocument');
        }
    };
    const downloadCOCDocument = (document) => {
        const fileDto = { folderName: document?.folderName, fileName: document?.name };
        dispatch(downloadCOCDocumentAsync(fileDto))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>Chamber of Commerce Document</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    useEffect(() => {
        const redirect_url = searchParams.get('redirect-url');
        if (redirect_url === 'shuffti-pro') {
            dispatch(toggleKycModal(false));
            const shufftiProStatus = LocalStorageService.getShufftiProStatus();
            if (shufftiProStatus) {
                const response = JSON.parse(shufftiProStatus);
                if (response.event === 'verification.accepted') {
                    dispatch(toggleKycResponseModal());
                }
                else {
                    dispatch(toggleKycModal(false));
                    dispatch(toggleKycErrorResponseModal());
                }
            }
        }
    }, [dispatch, searchParams]);
    useEffect(() => {
        reset({ ...freelancer, identityDocuments: [] });
    }, [freelancer, reset]);
    useEffect(() => {
        const redirect_url = searchParams.get('redirect-url');
        const status_text = searchParams.get('status');
        if (freelancer) {
            freelancer.dateOfBirth === null ? setStartDate(null) : setStartDate(moment(freelancer.dateOfBirth).toDate());
        }
        if ((freelancer?.documentNumber === null || freelancer.status === UserStatus.PendingKyc) && !redirect_url && !status_text) {
            dispatch(toggleKycModal(true));
        }
    }, [freelancer, dispatch, searchParams]);
    useEffect(() => {
        freelancer &&
            setStatusDisabled(freelancer.status === UserStatus.Approved || freelancer.status === UserStatus.Disapproved ? true : false);
    }, [freelancer, statusDisabled]);
    const statusClass = freelancer.status === UserStatus.Approved
        ? 'Approved'
        : freelancer.status === UserStatus.Pending || freelancer.status === UserStatus.PendingKyc
            ? 'Pending'
            : 'Disapproved';
    return (_jsx("div", { className: 'account__section account-form', children: _jsxs("div", { className: 'account__form', children: [_jsxs("div", { className: 'account__crate__title', children: [_jsx("h3", { children: translate('account.personalInformation') }), !!freelancer.status && _jsx("div", { className: statusClass, children: UserStatusMap.get(freelancer.status) })] }), _jsxs("form", { onSubmit: handleSubmit(onSubmit), noValidate: true, children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.firstName') }), _jsx("input", { type: 'text', className: `${'disabled'}`, disabled: true, placeholder: translate('account.firstName'), ...register('firstName', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    minLength: {
                                                        value: 2,
                                                        message: translate('validations.minimumCharactersAllowed', { count: 2 })
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                                    },
                                                    pattern: {
                                                        value: RegExp('^[a-z A-Z]+$'),
                                                        message: translate('validations.invalidFirstName')
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.firstName })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.lastName') }), _jsx("input", { type: 'text', disabled: true, className: `${'disabled'}`, placeholder: translate('account.lastName'), ...register('lastName', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    minLength: {
                                                        value: 2,
                                                        message: translate('validations.minimumCharactersAllowed', { count: 2 })
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                                    },
                                                    pattern: {
                                                        value: RegExp('^[a-z A-Z]+$'),
                                                        message: translate('validations.invalidLastName')
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.lastName })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.dateOfBirth') }), _jsx(Controller, { name: 'dateOfBirth', control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    min: {
                                                        value: moment(new Date('1950-01-01')).toString(),
                                                        message: translate('validations.invalidDateOfBirth')
                                                    },
                                                    max: {
                                                        value: moment().subtract(18, 'years').toString(),
                                                        message: translate('validations.invalidDateOfBirth')
                                                    }
                                                }, render: ({ field: { onChange, value } }) => (_jsx(DatePicker, { disabled: true, className: `${'disabled'}`, peekNextMonth: true, showMonthDropdown: true, showYearDropdown: true, dropdownMode: 'select', minDate: new Date('1950-01-01'), maxDate: new Date(moment().subtract(18, 'years').toString()), placeholderText: 'dd/mm/yyyy', dateFormat: 'dd/MM/yyyy', selected: startDate, onChange: setDateOfBirth })) }), _jsx(FqValidation, { fieldError: errors.dateOfBirth })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.documentNumber') }), _jsx("input", { type: 'text', disabled: true, className: `${'disabled'}`, placeholder: translate('account.documentNumber'), ...register('documentNumber', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.documentNumber })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.telephoneNumber') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.telephoneNumber'), ...register('phoneNumber', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.phoneNumber })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.residentialAddress') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.residentialAddress'), ...register('address', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.address })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.zipCode') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.zipCode'), ...register('zipCode', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 10 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.zipCode })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group country', children: [_jsx("label", { children: translate('account.country') }), _jsx(Controller, { name: 'countryId', control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    }
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Select, { isDisabled: true, placeholder: translate('account.country'), isSearchable: false, isClearable: true, styles: selectStyles, value: countriesDropdown?.find((c) => c.value === value), options: countriesDropdown, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.countryId })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.email') }), _jsx("input", { type: 'text', className: `disabled`, disabled: true, placeholder: 'Email', value: freelancer.email })] }) })] }), _jsx("div", { className: 'account__crate__title two', children: _jsx("h3", { children: translate('account.companyInformation') }) }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { htmlFor: 'cname', children: translate('account.companyName') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.companyName'), ...register('companyName', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 50 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.companyName })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.cci') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.cci'), ...register('cci', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.cci })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { htmlFor: 'vat', children: translate('account.vatNumber') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.vatNumber'), ...register('vat', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.vat })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { htmlFor: 'title', children: translate('account.bankAccountTitle') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.bankAccountTitle'), ...register('bankAccountTitle', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    minLength: {
                                                        value: 2,
                                                        message: translate('validations.minimumCharactersAllowed', { count: 2 })
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 50 })
                                                    },
                                                    pattern: {
                                                        value: RegExp('^[A-Za-z0-9 ]+$'),
                                                        message: translate('validations.invalidBankAccountTitle')
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.bankAccountTitle })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.bankAccountNumber') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.bankAccountNumber'), ...register('bankAccountNumber', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.bankAccountNumber })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.businessAddress') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.businessAddress'), ...register('companyAddress', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.companyAddress })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.zipCode') }), _jsx("input", { type: 'text', disabled: statusDisabled, className: `${statusDisabled && 'disabled'}`, placeholder: translate('account.zipCode'), ...register('companyZipCode', {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    },
                                                    maxLength: {
                                                        value: 10,
                                                        message: translate('validations.maximumCharactersAllowed', { count: 10 })
                                                    }
                                                }) }), _jsx(FqValidation, { fieldError: errors.companyZipCode })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: `form-group ${statusDisabled && 'country'}`, children: [_jsx("label", { children: translate('account.companyCountry') }), _jsx(Controller, { name: 'companyCountry', control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    }
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Select, { menuPlacement: 'top', isDisabled: statusDisabled, placeholder: translate('account.companyCountry'), isSearchable: true, isClearable: true, styles: selectStyles, value: countriesDropdown?.find((c) => c.value === value?.toLocaleLowerCase()), options: countriesDropdown, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.companyCountry })] }) }), _jsx("div", { className: 'col-lg-4 col-md-6 col-sm-12', children: _jsxs("div", { className: `form-group ${statusDisabled && 'userType'}`, children: [_jsx("label", { children: translate('account.userType') }), _jsx(Controller, { name: 'userType', control: control, rules: {
                                                    required: {
                                                        value: true,
                                                        message: translate('validations.fieldIsRequired')
                                                    }
                                                }, render: ({ field: { onChange, value } }) => (_jsx(Select, { menuPlacement: 'top', isDisabled: statusDisabled, placeholder: translate('account.pleaseSelect'), isSearchable: true, isClearable: true, styles: selectStyles, value: userTypesDropDown?.find((c) => c.value === value), options: userTypesDropDown, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.userType })] }) }), freelancer.status === UserStatus.Pending ? (_jsx(_Fragment, { children: _jsxs("div", { className: 'form-group COCFileUpload', children: [_jsx("label", { className: 'file-upload-label align-self-center', htmlFor: 'file-upload', children: _jsxs("div", { className: 'd-flex', children: [_jsx("div", { className: 'file-upload-icon', children: _jsx(FontAwesomeIcon, { icon: 'cloud-arrow-up', fontSize: '1.2em', color: '#FF0000' }) }), _jsx("h3", { className: 'align-self-center ms-2', children: translate('account.COCDocumentLabel') })] }) }), _jsx(Controller, { name: 'COCDocument', control: control, rules: {
                                                    required: freelancer?.cocDocument?.type !== 'COC' ? { message: translate('validations.fieldIsRequired'), value: true } : false
                                                }, render: () => _jsx("input", { accept: 'application/pdf', type: 'file', id: 'file-upload', hidden: true, onChange: uploadFile }) }), uploadedFile && _jsx("div", { className: 'uploaded-file', children: uploadedFile?.name }), _jsx(FqValidation, { fieldError: errors.COCDocument })] }) })) : (''), _jsx("div", { className: 'col-lg-4', children: _jsx("div", { className: 'form-group', children: _jsx("ul", { className: 'list-group', children: freelancer?.cocDocument && freelancer.cocDocument.type === 'COC' && (_jsx("li", { className: 'list-group-item', children: _jsx("span", { className: 'medium-font', onClick: () => downloadCOCDocument(freelancer.cocDocument), children: `${freelancer.cocDocument.name.split('-')[0]}...${freelancer.cocDocument.name.split('.')[freelancer.cocDocument.name.split('.').length - 1]}` }) })) }) }) })] }), freelancer.status === UserStatus.Approved || freelancer.status === UserStatus.Disapproved ? (_jsxs(_Fragment, { children: [_jsx("div", { className: 'worning__account', children: _jsx("span", { children: translate('account.accountChangeDisabledMessage') }) }), _jsx("div", { className: 'account__btn-request', children: _jsx("button", { className: 'btn__one', type: 'button', onClick: () => navigate('/support'), children: _jsx("span", { className: 'text', children: translate('account.requestForChanges') }) }) })] })) : (_jsx(_Fragment, { children: _jsx("div", { className: 'account__btn', children: _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: translate('common.save') }) }) }))] })] }) }));
};
export default AccountFrom;
