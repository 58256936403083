import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { InvoiceStatus, InvoiceStatusMap } from '@application/common/enums/invoice-status';
import { translate } from '@application/common/helpers/translate';
import { formatDMY } from '@infrastructure/helpers';
import FqButton from '@ui/components/FqButton';
import moment from 'moment';
import './style.css';
const ProcessedInvoicesList = ({ invoices = [], status, onReceivedInvoice, viewProcessedInvoice }) => {
    const dueDateClassName = (dueDate, invoiceStatus) => {
        if (invoiceStatus === InvoiceStatus.Approved) {
            const daysDiff = moment(dueDate).diff(moment(), 'days');
            return daysDiff > 0 && daysDiff <= 3 ? 'text-warning' : daysDiff <= 0 ? 'text-danger' : '';
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "ID" }), _jsx("th", { children: "Freelancer Name" }), _jsx("th", { children: "Invoice Amount" }), _jsx("th", { children: "Status" }), _jsx("th", { children: "Due Date" }), _jsx("th", { children: "Processed Date" }), _jsx("th", {})] }) }), invoices && invoices.length > 0 && (_jsx("tbody", { children: invoices.map((invoice) => {
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.invoiceNo }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.freelancerName }) }), _jsx("td", { children: _jsxs("div", { className: 'mt-2', children: ["\u20AC", invoice.invoiceAmountIncludingVAT.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            })] }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: InvoiceStatusMap.get(invoice.status) }) }), _jsx("td", { children: _jsx("div", { className: `mt-2 ${dueDateClassName(invoice.debtorPaymentDueDate, invoice.status) ?? ''}`, children: formatDMY(invoice.debtorPaymentDueDate) }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: formatDMY(invoice.processedDate) }) }), _jsx("td", { children: _jsxs("div", { className: 'mt-2 d-flex justify-content-end', children: [_jsx(FqButton, { mode: 'secondary', size: 'sm', onClick: () => viewProcessedInvoice(invoice.id), children: "View" }), invoice.status !== InvoiceStatus.Disapproved && (_jsx(FqButton, { customClassName: `ms-2`, mode: 'primary', size: 'sm', onClick: () => onReceivedInvoice(invoice.id), children: "Approve" }))] }) })] }, invoice.id));
                    }) })), status === APIStatus.IDLE && invoices && !invoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 12, children: translate('common.noRecordFound') }) }) }))] }) }));
};
export default ProcessedInvoicesList;
