import moment from 'moment';
export const convertModelToFormData = (data, form = new FormData(), namespace = '') => {
    let files = {};
    let model = {};
    for (let propertyName in data) {
        if (!data.hasOwnProperty(propertyName) || !data[propertyName])
            continue;
        if (data[propertyName] instanceof Array) {
            if (data[propertyName].every((x) => x instanceof File)) {
                files[propertyName] = data[propertyName];
            }
            else {
                model[propertyName] = data[propertyName];
            }
        }
        else if (data[propertyName] instanceof File || data[propertyName]) {
            files[propertyName] = data[propertyName];
        }
        else {
            model[propertyName] = data[propertyName];
        }
    }
    let formData = form || new FormData();
    for (let propertyName in model) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName])
            continue;
        let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (model[propertyName] instanceof Date)
            formData.append(formKey, model[propertyName].toISOString());
        else if (model[propertyName] instanceof File) {
            formData.append(formKey, model[propertyName]);
        }
        else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach((element, index) => {
                const tempFormKey = `${formKey}[${index}]`;
                if (typeof element === 'object')
                    convertModelToFormData(element, formData, tempFormKey);
                else
                    formData.append(tempFormKey, element.toString());
            });
        }
        else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
            convertModelToFormData(model[propertyName], formData, formKey);
        else {
            formData.append(formKey, model[propertyName].toString());
        }
    }
    for (let propertyName in files) {
        if (files.hasOwnProperty(propertyName)) {
            if (files[propertyName] instanceof Array) {
                files[propertyName].forEach((element) => {
                    formData.append(propertyName, element);
                });
            }
            else
                formData.append(propertyName, files[propertyName]);
        }
    }
    return formData;
};
export const convertDateTime = (date) => {
    if (date !== null && date !== undefined)
        return moment(date).format('YYYY-MM-DD');
    return '';
};
export const formatDMY = (date) => {
    if (date !== null && date !== undefined)
        return moment(date).format('DD-MM-YYYY');
    return '';
};
export const formatDateDMY = (date) => {
    if (date !== null && date !== undefined)
        return moment(date).format('DD-MM-YYYY');
    return '';
};
export const formatMDY = (date) => {
    if (date !== null && date !== undefined)
        return moment(date).format('MM-DD-YYYY');
    return '';
};
export const formatYMD = (date) => {
    if (date !== null && date !== undefined)
        return moment(date).format('YYYY-MM-DD');
    return '';
};
export const preventBackspaceAndDelete = ((ref) => {
    const handleKeyDown = (event) => {
        if (typeof event.key === 'string' || (event.key === 'Backspace' || event.key === 'Delete')) {
            event.preventDefault();
            return;
        }
    };
    const datepickerElement = ref.current?.input;
    if (datepickerElement) {
        datepickerElement.addEventListener('keydown', handleKeyDown);
    }
    return () => {
        if (datepickerElement) {
            datepickerElement.removeEventListener('keydown', handleKeyDown);
        }
    };
});
