import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { createInvoiceAsync, getUserInvoicesAsync, getUsersAcceptedDebtorsAsync, getFreelancerChecklistAsync, getInvoiceByIdAsync, updateInvoiceStatusAsync, getUserInvoicesByStatusAsync, getUserPaidInvoicesAsync } from './invoiceThunk';
import { AppConsts } from '@application/common/constants/app-consts';
import { InvoiceStatus } from '@application/common/enums/invoice-status';
const freelancerInvoicesRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE
};
const initialState = {
    status: APIStatus.IDLE,
    createInvoiceModalOpen: false,
    updateInvoiceStatusModalOpen: false,
    freelancerInvoices: [],
    freelancerDebtors: [],
    freelancersInvoiceChecklist: [],
    freelancerDebtorsList: [],
    freelancerPaymentPackages: [],
    freelancerInvoicesRequest: freelancerInvoicesRequest,
    freelancerInvoicesByStatusRequest: {
        pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
        pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE,
        status: InvoiceStatus.Approved
    },
    userPaidInvoicesRequest: {
        pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
        pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE
    },
    viewFreelancerInvoiceModalOpen: false
};
export const invoiceSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        toggleCreateInvoiceModal(state) {
            state.createInvoiceModalOpen = !state.createInvoiceModalOpen;
        },
        toggleUpdateInvoiceStatusModal(state) {
            state.invoice = state.updateInvoiceStatusModalOpen ? undefined : state.invoice;
            state.updateInvoiceStatusModalOpen = !state.updateInvoiceStatusModalOpen;
        },
        setFreelancerInvoicesRequest(state, action) {
            state.freelancerInvoicesRequest = action.payload;
        },
        setFreelancerInvoicesByStatusRequest(state, action) {
            state.freelancerInvoicesByStatusRequest = action.payload;
        },
        setUserPaidInvoicesRequest(state, action) {
            state.userPaidInvoicesRequest = action.payload;
        },
        toggleViewFreelancerInvoiceModal(state) {
            state.invoice = state.viewFreelancerInvoiceModalOpen ? undefined : state.invoice;
            state.viewFreelancerInvoiceModalOpen = !state.viewFreelancerInvoiceModalOpen;
        },
        resetFreelancerInvoices(state) {
            state.freelancerInvoicesPaginated = undefined;
            state.freelancerInvoicesRequest = freelancerInvoicesRequest;
        },
        resetFreelancerInvoice(state) {
            state.invoice = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerInvoicesPaginated = action.payload;
        })
            .addCase(getUserInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserInvoicesByStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserInvoicesByStatusAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerInvoicesPaginated = action.payload;
        })
            .addCase(getUserInvoicesByStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getInvoiceByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getInvoiceByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.invoice = action.payload;
        })
            .addCase(getInvoiceByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(createInvoiceAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(createInvoiceAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerInvoices.push(action.payload);
            state.createInvoiceModalOpen = false;
        })
            .addCase(createInvoiceAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateInvoiceStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateInvoiceStatusAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateInvoiceStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUsersAcceptedDebtorsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUsersAcceptedDebtorsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerDebtorsList = action.payload;
            state.freelancerDebtors = action.payload.map((debtor) => {
                return {
                    label: debtor.companyName,
                    value: debtor.debtorId
                };
            });
            state.freelancerPaymentPackages = action.payload.map((debtor) => debtor.paymentPackage);
        })
            .addCase(getUsersAcceptedDebtorsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getFreelancerChecklistAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFreelancerChecklistAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancersInvoiceChecklist = state.freelancersInvoiceChecklist = action.payload;
        })
            .addCase(getFreelancerChecklistAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserPaidInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserPaidInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerInvoicesPaginated = action.payload;
        })
            .addCase(getUserPaidInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { toggleCreateInvoiceModal, toggleUpdateInvoiceStatusModal, setFreelancerInvoicesRequest, setFreelancerInvoicesByStatusRequest, toggleViewFreelancerInvoiceModal, resetFreelancerInvoices, resetFreelancerInvoice, setUserPaidInvoicesRequest } = invoiceSlice.actions;
export default invoiceSlice.reducer;
