import { translate } from '../helpers/translate';
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus[InvoiceStatus["Pending"] = 0] = "Pending";
    InvoiceStatus[InvoiceStatus["Approved"] = 1] = "Approved";
    InvoiceStatus[InvoiceStatus["Disapproved"] = 2] = "Disapproved";
    InvoiceStatus[InvoiceStatus["Received"] = 3] = "Received";
})(InvoiceStatus || (InvoiceStatus = {}));
export const InvoiceStatusMap = new Map([
    [InvoiceStatus.Pending, translate('enums.invoiceStatus.pending')],
    [InvoiceStatus.Approved, translate('enums.invoiceStatus.approved')],
    [InvoiceStatus.Disapproved, translate('enums.invoiceStatus.rejected')],
    [InvoiceStatus.Received, translate('enums.invoiceStatus.received')]
]);
