import { getErrorPayload } from '@application/common/helpers/api-helper';
import PaymentPackageApi from '@infrastructure/payment-package-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getFreelancerPaymentPackagesAsync = createAsyncThunk('paymentPackage/getForFreelancer', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await PaymentPackageApi.getFreelancerPaymentPackages();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
