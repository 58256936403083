import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { InvoiceDocumentType } from '@application/common/enums/invoice-document-type';
import { InvoiceStatus, InvoiceStatusMap } from '@application/common/enums/invoice-status';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { translate } from '@application/common/helpers/translate';
import { downloadInvoiceDocumentAsync } from '@application/features/invoices/invoiceThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { formatDMY } from '@infrastructure/helpers';
import FqButton from '@ui/components/FqButton';
const InvoiceList = ({ filteredInvoices, status, viewFreelancerInvoice }) => {
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.auth);
    const downloadInvoice = (invoiceId) => {
        const invoice = filteredInvoices?.find((x) => x.id === invoiceId);
        const invoiceDocument = invoice?.invoiceDocuments.find((x) => x.type === InvoiceDocumentType.Invoice);
        const fileDownloadInput = { folderName: invoiceDocument?.folderName, fileName: invoiceDocument?.name };
        dispatch(downloadInvoiceDocumentAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>Invoice-${currentUser?.name}</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "ID" }), _jsx("th", { children: translate('invoice.debtor') }), _jsx("th", { children: translate('invoice.invoiceAmount') }), _jsx("th", { children: translate('common.faqtoringStatus') }), _jsx("th", { children: translate('invoice.dueDate') }), _jsx("th", { children: translate('invoice.remainingHours') }), _jsx("th", { children: translate('common.invoice') })] }) }), filteredInvoices && filteredInvoices.length > 0 && (_jsx("tbody", { children: filteredInvoices &&
                        filteredInvoices.map((invoice) => {
                            return (_jsxs("tr", { children: [_jsx("td", { children: invoice.invoiceNo }), _jsx("td", { children: invoice.debtor?.companyName }), _jsx("td", { children: invoice.invoiceAmount.toLocaleString('nl', {
                                            maximumFractionDigits: 2
                                        }) }), _jsx("td", { children: InvoiceStatusMap.get(invoice.status) }), _jsx("td", { children: formatDMY(invoice.invoicePaymentDueDate) }), _jsx("td", { children: invoice.status === InvoiceStatus.Pending ? invoice.remainingHours : '---' }), _jsx("td", { children: _jsxs("div", { className: 'd-flex', children: [_jsx(FqButton, { mode: 'secondary', size: 'sm', onClick: () => viewFreelancerInvoice(invoice.id), children: translate('common.view') }), _jsx(FqButton, { customClassName: 'ms-2', mode: 'primary', size: 'sm', onClick: () => downloadInvoice(invoice.id), children: translate('common.download') })] }) })] }, invoice.invoiceNo));
                        }) })), status === APIStatus.IDLE && filteredInvoices && !filteredInvoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 7, children: translate('invoice.noRecordsInMyInvoiceGrid') }) }) }))] }) }));
};
export default InvoiceList;
