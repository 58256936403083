import { InvoiceStatus } from '@application/common/enums/invoice-status';
import { faqtoringApi } from './faqtoring-api';
import { convertModelToFormData } from './helpers';
const createInvoice = (invoice) => {
    const formData = convertModelToFormData(invoice);
    return faqtoringApi.postForm('invoice', formData);
};
const getUserInvoices = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`invoice/UserInvoices?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getUserInvoicesByStatus = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`invoice/UserInvoicesByStatus/${request.status}?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getUserPaidInvoices = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`invoice/GetUserPaidInvoices?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getUserInvoicesById = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`invoice/UserInvoices/${request.userId}?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getInvoiceById = (invoiceId) => {
    return faqtoringApi.get(`invoice/${invoiceId}`);
};
const getInvoicesByStatus = (invoiceByStatusRequest) => {
    const filter = `&filter=${invoiceByStatusRequest.filter ? invoiceByStatusRequest.filter : ''}`;
    return faqtoringApi.get(`invoice/InvoicesByStatus/${invoiceByStatusRequest.invoiceStatus}?pageNumber=${invoiceByStatusRequest.pageNumber}&pageSize=${invoiceByStatusRequest.pageSize}${filter}`);
};
const getProcessedInvoices = (request) => {
    const filter = `&filter=${request.filter ? request.filter : ''}`;
    return faqtoringApi.get(`invoice/Processed?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}${filter}`);
};
const getFreelancerOutstandingInvoices = (filterBy) => {
    return faqtoringApi.get(`invoice/getFreelancerOutstandingInvoices/${filterBy}`);
};
const getFrelancerInvoicesChecklist = () => {
    return faqtoringApi.get('invoice/GetFreelancerInvoiceChecklist');
};
const approvedProcessedInvoice = (invoiceId, ReceivedDate) => {
    const obj = { id: invoiceId, status: InvoiceStatus.Received, ReceivedDate };
    return faqtoringApi.put(`invoice/UpdateInvoiceStatus`, obj);
};
const updateInvoiceStatus = (invoiceStatus) => {
    return faqtoringApi.put('invoice/UpdateInvoiceStatus', invoiceStatus);
};
const InvoiceApi = {
    createInvoice,
    getUserInvoices,
    getFrelancerInvoicesChecklist,
    getInvoicesByStatus,
    getUserInvoicesById,
    getProcessedInvoices,
    approvedProcessedInvoice,
    getFreelancerOutstandingInvoices,
    getInvoiceById,
    updateInvoiceStatus,
    getUserInvoicesByStatus,
    getUserPaidInvoices
};
export default InvoiceApi;
