import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { logout } from '@application/features/auth/authSlice';
import { toggleResponsiveMenu } from '@application/features/layout/layoutSlice';
import { useAppDispatch } from '@application/store/useStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
const AdminMenuItems = ({ isResponsiveMenu }) => {
    const dispatch = useAppDispatch();
    const close = () => {
        if (isResponsiveMenu)
            dispatch(toggleResponsiveMenu());
    };
    return (_jsxs("ul", { children: [_jsx("li", { children: _jsxs(NavLink, { to: '/admin/outstanding-invoices', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'receipt' }), _jsx("span", { className: 'ms-3', children: "Outstanding Invoices" })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: '/admin/processed-invoices', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'receipt' }), _jsx("span", { className: 'ms-3', children: "Processed Invoices" })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: '/admin/received-invoices', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'receipt' }), _jsx("span", { className: 'ms-3', children: "Received Invoices" })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: '/admin/users', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'user' }), _jsx("span", { className: 'ms-3', children: "Users" })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: '/admin/debtors', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'sack-dollar' }), _jsx("span", { className: 'ms-3', children: "Debtors" })] }) }), isResponsiveMenu && (_jsx("li", { children: _jsxs(NavLink, { to: '', className: ({ isActive }) => (isActive ? '' : ''), onClick: () => {
                        dispatch(toggleResponsiveMenu());
                        dispatch(logout());
                    }, children: [_jsx(FontAwesomeIcon, { icon: 'sign-out-alt' }), _jsx("span", { className: 'ms-3', children: "Sign out" })] }) }))] }));
};
export default AdminMenuItems;
