import { faqtoringApi } from './faqtoring-api';
const sendSupportMessage = async (createSupportDto) => {
    return await faqtoringApi.post('supports', createSupportDto);
};
const getSupportMessages = async () => {
    return await faqtoringApi.get('supports');
};
const SupportApi = {
    sendSupportMessage,
    getSupportMessages
};
export default SupportApi;
