import { getErrorPayload } from '@application/common/helpers/api-helper';
import UserApi from '@infrastructure/user-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getCurrentUserDetailsAsync = createAsyncThunk('users/getCurrentUserDetails', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await UserApi.getCurrentUserDetails();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
