export const selectStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    container: (provided) => ({
        ...provided,
        fontFamily: "'Mulish', sans-serif",
        zIndex: '100'
    }),
    control: (provided) => ({
        ...provided,
        height: '45px',
        border: '1px solid #d3d4d8',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#d3d4d8'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        marginTop: '-6px',
        fontSize: 14,
        color: '#676f7a'
    }),
    placeholder: (provided) => ({
        ...provided,
        marginTop: '-6px'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        marginTop: '-6px'
    }),
    clearIndicator: (provided) => ({
        ...provided,
        marginTop: '-6px'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    menuList: (provided) => ({
        ...provided,
        backgroundColor: '#0B1315'
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        color: 'white',
        backgroundColor: isFocused ? '#727875' : '#0B1315',
        '&:active': {
            backgroundColor: '#727875'
        }
    })
};
