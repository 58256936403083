import { getErrorPayload } from '@application/common/helpers/api-helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setButtonState } from '../common/buttonStateSlice';
import ShufftiProApi from '@infrastructure/shuffti-pro-api';
import LocalStorageService from '@infrastructure/local-storage-service';
import { toggleKycErrorResponseModal, toggleKycModal, toggleKycResponseModal } from '../freelancer/freelancerSlice';
export const verifyUserAsync = createAsyncThunk('shuffti-pro/verify', async (userVerifyDto, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await ShufftiProApi.userVerify(userVerifyDto);
        LocalStorageService.setReferenceNumber(response.data.reference);
        const result = fulfillWithValue(response.data);
        dispatch(toggleKycModal(false));
        return result;
    }
    catch (error) {
        dispatch(setButtonState(false));
        return rejectWithValue(getErrorPayload(error));
    }
});
export const getShufftiProStatusAsync = createAsyncThunk('shuffti-pro/status', async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
        const response = await ShufftiProApi.getStatus(request);
        const shufftiPro = response.data;
        const returnResponse = fulfillWithValue(shufftiPro);
        LocalStorageService.setShufftiProStatus(JSON.stringify(shufftiPro));
        if (shufftiPro.event === 'verification.accepted') {
            dispatch(toggleKycResponseModal());
        }
        else {
            dispatch(toggleKycModal(false));
            dispatch(toggleKycErrorResponseModal());
        }
        return returnResponse;
    }
    catch (error) {
        const errorResult = rejectWithValue(getErrorPayload(error));
        dispatch(toggleKycErrorResponseModal());
        return errorResult;
    }
});
