import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DebtorDocumentType } from '@application/common/enums/debtor-document-type';
import { InvoiceDocumentType } from '@application/common/enums/invoice-document-type';
import { InvoiceStatus, InvoiceStatusMap } from '@application/common/enums/invoice-status';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { getUserBalanceAsync } from '@application/features/adminUsers/adminUserThunk';
import { downloadDebtorContractAsync } from '@application/features/debtors/debtorThunks';
import { downloadInvoiceDocumentAsync, updateInvoiceStatusAsync } from '@application/features/invoices/invoiceThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import FqValidation from '@ui/components/FqValidation';
import { selectStyles } from '@ui/styles/select-style';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { translate } from '@application/common/helpers/translate';
import { formatDMY } from '@infrastructure/helpers';
import { APIStatus } from '@application/common/enums/api-status';
const ApproveInvoiceForm = ({ invoice, balance, onCancel, isViewOnly }) => {
    const { status } = useAppSelector((state) => state.invoice);
    const invoicesStatuses = [
        {
            label: 'Approved',
            value: InvoiceStatus.Approved
        },
        {
            label: 'Rejected',
            value: InvoiceStatus.Disapproved
        }
    ];
    const dispatch = useAppDispatch();
    const { handleSubmit, register, control, watch, setValue, clearErrors, formState: { errors } } = useForm({
        defaultValues: {
            status: InvoiceStatus.Approved
        }
    });
    const selectedStatus = watch('status');
    const getInvoicePackage = () => {
        if (!invoice || !invoice.invoicePackage)
            return '';
        return `${invoice.invoicePackage.title}: ${invoice.invoicePackage.paymentDays} work days, ${invoice.invoicePackage.percentage.toLocaleString('nl')}%`;
    };
    const getPaymentPackage = () => {
        if (!invoice || !invoice.debtor.paymentPackage)
            return '';
        return `${invoice.debtor.paymentPackage.title}`;
    };
    useEffect(() => {
        if (invoice) {
            setValue('id', invoice.id);
            dispatch(getUserBalanceAsync(invoice.freelancerId));
        }
    }, [dispatch, invoice, setValue]);
    const onSubmit = (invoiceStatus) => {
        dispatch(updateInvoiceStatusAsync(invoiceStatus));
    };
    const onDownloadDebtorsContract = () => {
        const contractDocument = invoice?.debtor.debtorDocuments.find((x) => x.type === DebtorDocumentType.DebtorContract);
        const fileDownloadInput = { folderName: contractDocument?.folderName, fileName: contractDocument?.name };
        dispatch(downloadDebtorContractAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>DebtorsContract-${invoice?.freelancerName}</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    const onDownloadInvoice = () => {
        const contractDocument = invoice?.invoiceDocuments.find((x) => x.type === InvoiceDocumentType.Invoice);
        const fileDownloadInput = { folderName: contractDocument?.folderName, fileName: contractDocument?.name };
        dispatch(downloadDebtorContractAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>Invoice-${invoice?.freelancerName}</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    const downloadInvoiceDocuments = (document) => {
        const fileDto = { folderName: document?.folderName, fileName: document?.name };
        dispatch(downloadInvoiceDocumentAsync(fileDto))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>TimeSheet Document-${invoice?.freelancerName}</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsxs("div", { className: 'd-flex justify-content-between', children: [_jsx("label", { children: translate('invoice.debtor') }), _jsx("button", { type: 'button', className: 'btn btn-link btn-link', onClick: () => onDownloadDebtorsContract(), children: translate('invoice.viewDebtorsContract') })] }), _jsx("input", { type: 'text', value: invoice?.debtor.companyName ?? '', disabled: true })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsxs("div", { className: 'd-flex justify-content-between', children: [_jsx("label", { children: translate('invoice.invoiceAmountExcludingVAT') }), _jsx("button", { type: 'button', className: 'btn btn-link btn-link', onClick: () => onDownloadInvoice(), children: translate('invoice.viewInvoice') })] }), _jsx("input", { type: 'text', value: invoice?.invoiceAmount?.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            }) ?? '', disabled: true })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.paymentPackage') }), _jsx("input", { type: 'text', value: getPaymentPackage(), disabled: true })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.invoicePackage') }), _jsx("input", { type: 'text', value: getInvoicePackage(), disabled: true })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.totalFee') }), _jsx("input", { type: 'text', value: (invoice &&
                                                (invoice.paymentPackageFee + invoice.invoicePackageFee).toLocaleString('nl', {
                                                    maximumFractionDigits: 2
                                                })) ??
                                                '', disabled: true })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.currentOutstandingBalance') }), _jsx("input", { type: 'text', value: balance?.outstandingBalance?.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            }) || 0, disabled: true })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.payableInvoiceAmount') }), _jsx("input", { type: 'text', value: invoice?.payableInvoiceAmount?.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            }) ?? '', disabled: true })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.dueDate') }), _jsx("input", { type: 'text', value: (invoice && formatDMY(invoice?.dueDate)) ?? '', disabled: true })] }) })] }), _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("div", { className: 'd-flex justify-content-between', children: _jsx("label", { children: translate('common.status') }) }), !isViewOnly && invoice?.status === InvoiceStatus.Pending ? (_jsx(Controller, { name: 'status', control: control, rules: {
                                                required: {
                                                    value: true,
                                                    message: 'This field is required.'
                                                }
                                            }, render: ({ field: { onChange, value } }) => (_jsx(Select, { isDisabled: invoice.status === InvoiceStatus.Approved, isSearchable: false, value: invoicesStatuses.find((c) => c.value === value), options: invoicesStatuses, styles: selectStyles, onChange: (val) => {
                                                    onChange(val?.value);
                                                    if (val?.value === InvoiceStatus.Approved) {
                                                        setValue('reason', '');
                                                        clearErrors('reason');
                                                    }
                                                } })) })) : (_jsx("input", { disabled: true, type: 'text', value: (invoice && InvoiceStatusMap.get(invoice.status)) ?? '' })), _jsx(FqValidation, { fieldError: errors.status })] }) }), _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.vat') }), _jsx("input", { type: 'text', value: invoice?.vat ?? '', disabled: true })] }) })] }), _jsx("div", { className: "row", children: _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.fixedFee') }), _jsx("input", { type: 'text', value: invoice?.debtor.fixedFee ?? '', disabled: true })] }) }) }), selectedStatus === InvoiceStatus.Disapproved && (_jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-12 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: "Reason" }), _jsx("textarea", { style: { height: 150, width: '100%', borderRadius: '3px' }, ...register('reason', {
                                            validate: {
                                                required: (value) => {
                                                    if (!value && selectedStatus === InvoiceStatus.Disapproved)
                                                        return 'This field is required.';
                                                }
                                            }
                                        }) }), _jsx(FqValidation, { fieldError: errors.reason })] }) }) })), _jsx("div", { className: 'row', children: _jsx("div", { className: 'col-lg-6 col-sm-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { children: translate('invoice.timeSheetDocuments') }), _jsx("ul", { className: 'list-group', children: invoice?.invoiceDocuments &&
                                            invoice.invoiceDocuments
                                                .filter((i) => i.type === InvoiceDocumentType.Timesheet)
                                                ?.map((x, index) => (_jsx("li", { className: 'list-group-item', children: _jsx("span", { className: 'medium-font', onClick: () => downloadInvoiceDocuments(x), children: `${x.name.split('-')[0]}...${x.name.split('.')[x.name.split('.').length - 1]}` }) }, index))) })] }) }) })] }), _jsxs("div", { className: 'd-flex justify-content-end', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: onCancel, children: "Cancel" }), !isViewOnly && invoice?.status === InvoiceStatus.Pending && (_jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: "Save" }))] })] }));
};
export default ApproveInvoiceForm;
