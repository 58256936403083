import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { getShufftiProStatusAsync } from '@application/features/shufftiPro/ShufftiProThunks';
import { setShufftiProStatus } from '@application/features/shufftiPro/shufftiProSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import LocalStorageService from '@infrastructure/local-storage-service';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const ShufftiPro = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { shufftiProStatusRequestDto } = useAppSelector((state) => state.shufftiPro);
    // useEffect(() => {
    //     dispatch(toggleKycModal());
    // }, [dispatch]);
    useEffect(() => {
        const referenceNumber = LocalStorageService.getReferenceNumber();
        const referenceObj = {
            reference: referenceNumber ?? ''
        };
        if (referenceNumber && referenceObj) {
            dispatch(setShufftiProStatus(referenceObj));
        }
    }, [dispatch]);
    useEffect(() => {
        if (shufftiProStatusRequestDto) {
            dispatch(getShufftiProStatusAsync(shufftiProStatusRequestDto));
            navigate(`/account?redirect-url=shuffti-pro`);
        }
    }, [dispatch, navigate, shufftiProStatusRequestDto]);
    return (_jsx(_Fragment, {}));
};
export default ShufftiPro;
