import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { resetFreelancerBalance } from '@application/features/adminUsers/adminUserSlice';
import { resetFreelancerInvoice } from '@application/features/invoices/invoiceSlice';
import { getInvoiceByIdAsync } from '@application/features/invoices/invoiceThunk';
import { setReceivedInvoicesRequest, toggleViewProcessedInvoiceModal } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import { getReceivedInvoicesAsync } from '@application/features/invoicesByStatus/invoicesByStatusThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import FqPagination from '@ui/components/FqPagination';
import { useEffect } from 'react';
import ApproveInvoiceForm from '../outstandingInvoices/ApproveInvoiceForm';
import ReceivedInvoicesList from './ReceivedInvoicesList';
const ReceivedInvoices = () => {
    const dispatch = useAppDispatch();
    const { status, receivedInvoices, receivedInvoicesRequest, viewProcessedInvoiceModalOpen } = useAppSelector((state) => state.invoiceByStatus);
    const { invoice } = useAppSelector((state) => state.invoice);
    const { balance } = useAppSelector((state) => state.user);
    useEffect(() => {
        dispatch(getReceivedInvoicesAsync(receivedInvoicesRequest));
    }, [dispatch, receivedInvoicesRequest]);
    const handleSearch = (e) => {
        dispatch(setReceivedInvoicesRequest({ ...receivedInvoicesRequest, filter: e.target.value, pageNumber: 1 }));
    };
    const onPageChange = (currentPage) => {
        dispatch(setReceivedInvoicesRequest({ ...receivedInvoicesRequest, pageNumber: currentPage }));
    };
    const viewProcessedInvoice = (invoiceId) => {
        dispatch(getInvoiceByIdAsync(invoiceId));
        dispatch(toggleViewProcessedInvoiceModal());
    };
    const onCloseViewReveivedInvoiceModal = () => {
        dispatch(toggleViewProcessedInvoiceModal());
        dispatch(resetFreelancerBalance());
        dispatch(resetFreelancerInvoice());
    };
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: 'Received' }), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: 'Received Invoices', showSearch: true, onSearch: handleSearch }), children: _jsxs(_Fragment, { children: [_jsx(ReceivedInvoicesList, { invoices: receivedInvoices?.items, status: status, viewProcessedInvoice: viewProcessedInvoice }), receivedInvoices && (_jsx(FqPagination, { pageNumber: receivedInvoices?.pageNumber, totalPages: receivedInvoices?.totalPages, onPageChange: onPageChange }))] }) }), _jsx(FqDialog, { open: viewProcessedInvoiceModalOpen, onClose: onCloseViewReveivedInvoiceModal, title: '', maxWidth: 900, children: _jsx(ApproveInvoiceForm, { invoice: invoice, balance: balance, onCancel: onCloseViewReveivedInvoiceModal }) })] }));
};
export default ReceivedInvoices;
