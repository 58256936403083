import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import { useEffect } from 'react';
import { toggleCreateInvoiceModal, toggleViewFreelancerInvoiceModal, setUserPaidInvoicesRequest } from '@application/features/invoices/invoiceSlice';
import { getInvoiceByIdAsync, getUserPaidInvoicesAsync } from '@application/features/invoices/invoiceThunk';
import FqPagination from '@ui/components/FqPagination';
import { translate } from '@application/common/helpers/translate';
import InvoiceList from '../InvoiceList';
import CreateInvoiceForm from '../CreateInvoiceForm';
import ApproveInvoiceForm from '@ui/containers/admin/invoices/outstandingInvoices/ApproveInvoiceForm';
import { resetFreelancerBalance } from '@application/features/adminUsers/adminUserSlice';
const InvoicesByStatus = () => {
    const dispatch = useAppDispatch();
    const { createInvoiceModalOpen: createInvoiceModelOpen, freelancerInvoicesPaginated, status, userPaidInvoicesRequest, invoice, viewFreelancerInvoiceModalOpen } = useAppSelector((state) => state.invoice);
    const { balance } = useAppSelector((state) => state.user);
    useEffect(() => {
        dispatch(getUserPaidInvoicesAsync(userPaidInvoicesRequest));
    }, [dispatch, userPaidInvoicesRequest]);
    const handleSearch = (e) => {
        dispatch(setUserPaidInvoicesRequest({ ...userPaidInvoicesRequest, filter: e.target.value, pageNumber: 1 }));
    };
    const onfreelancerInvoicesPageChange = (currentPage) => {
        dispatch(setUserPaidInvoicesRequest({ ...userPaidInvoicesRequest, pageNumber: currentPage }));
    };
    const viewFreelancerInvoice = (invoiceId) => {
        dispatch(getInvoiceByIdAsync(invoiceId));
        dispatch(toggleViewFreelancerInvoiceModal());
    };
    const onCloseViewInvoiceModal = () => {
        dispatch(toggleViewFreelancerInvoiceModal());
        dispatch(resetFreelancerBalance());
    };
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: translate('invoice.myInvoices'), showAddButton: true, onAdd: () => dispatch(toggleCreateInvoiceModal()) }), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: translate('common.overview'), showSearch: true, onSearch: handleSearch }), children: _jsxs(_Fragment, { children: [_jsx(InvoiceList, { filteredInvoices: freelancerInvoicesPaginated?.items, status: status, viewFreelancerInvoice: viewFreelancerInvoice }), freelancerInvoicesPaginated && (_jsx(FqPagination, { pageNumber: freelancerInvoicesPaginated?.pageNumber, totalPages: freelancerInvoicesPaginated?.totalPages, onPageChange: onfreelancerInvoicesPageChange }))] }) }), _jsx(FqDialog, { open: createInvoiceModelOpen, maxWidth: 850, title: translate('invoice.addInvoice'), onClose: () => dispatch(toggleCreateInvoiceModal()), children: _jsx(CreateInvoiceForm, {}) }), _jsx(FqDialog, { open: viewFreelancerInvoiceModalOpen, onClose: onCloseViewInvoiceModal, title: '', maxWidth: 900, children: _jsx(ApproveInvoiceForm, { invoice: invoice, balance: balance, onCancel: onCloseViewInvoiceModal, isViewOnly: true }) })] }));
};
export default InvoicesByStatus;
