import { configureStore, combineReducers } from '@reduxjs/toolkit';
import loadingReducer from '../features/common/loadingSlice';
import notificationReducer from '../features/common/notificationSlice';
import authReducer from '../features/auth/authSlice';
import supportReducer from '../features/support/supportSlice';
import layoutReducer from '../features/layout/layoutSlice';
import freelancerReducer from '../features/freelancer/freelancerSlice';
import debtorReducer from '../features/debtors/debtorSlice';
import paymentPackageReducer from '../features/paymentPackages/paymentPackageSlice';
import invoiceReducer from '../features/invoices/invoiceSlice';
import invoicePackageReducer from '../features/invoicePackages/invoicePackageSlice';
import faqReducer from '../features/faqs/faqSlice';
import userReducer from '../features/adminUsers/adminUserSlice';
import invoicesByStatusReducer from '../features/invoicesByStatus/invoicesByStatusSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import buttonReducer from '../features/common/buttonStateSlice';
import shufftiProReducer from '../features/shufftiPro/shufftiProSlice';
import countryReducer from '../features/country/countrySlice';
import userTypeReducer from '../features/userType/userTypeSlice';
const combinedReducers = combineReducers({
    loading: loadingReducer,
    layout: layoutReducer,
    notification: notificationReducer,
    auth: authReducer,
    support: supportReducer,
    freelancer: freelancerReducer,
    debtor: debtorReducer,
    paymentPackage: paymentPackageReducer,
    invoice: invoiceReducer,
    invoicePackage: invoicePackageReducer,
    faq: faqReducer,
    user: userReducer,
    invoiceByStatus: invoicesByStatusReducer,
    dashboard: dashboardReducer,
    buttonState: buttonReducer,
    shufftiPro: shufftiProReducer,
    country: countryReducer,
    userType: userTypeReducer
});
const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        return combinedReducers(undefined, action);
    }
    return combinedReducers(state, action);
};
export const store = configureStore({
    reducer: rootReducer
});
