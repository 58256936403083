import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './application/store';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faPlus, faCircleDollarToSlot, faSearch, faXmark, faAngleDown, faCloudArrowUp, faAngleUp, faAnglesRight, faAnglesLeft, faSquarePollHorizontal, faUser, faSackDollar, faReceipt, faHeadset, faEllipsisV, faFileInvoice, faDollar, faAngleRight, faSignOutAlt, faFileInvoiceDollar, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
library.add(faTimes, faPlus, faSearch, faXmark, faAngleDown, faBell, faCircleDollarToSlot, faCloudArrowUp, faAngleUp, faAnglesRight, faAnglesLeft, faSquarePollHorizontal, faUser, faSackDollar, faReceipt, faHeadset, faEllipsisV, faFileInvoice, faDollar, faAngleRight, faSignOutAlt, faFileInvoiceDollar, faArrowRight);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(_jsx(I18nextProvider, { i18n: i18n, children: _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }) }));
