import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getFreelancerPaymentPackagesAsync } from './paymentPackageThunks';
const initialState = {
    status: APIStatus.IDLE,
    freelancerPaymentPackages: [],
    paymentPackages: []
};
export const paymentPackageSlice = createSlice({
    name: 'paymentPackage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFreelancerPaymentPackagesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFreelancerPaymentPackagesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.paymentPackages = action.payload;
            state.freelancerPaymentPackages = action.payload.map((paymentPackage) => {
                return {
                    label: `${paymentPackage.title}`,
                    value: paymentPackage.id
                };
            });
        })
            .addCase(getFreelancerPaymentPackagesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default paymentPackageSlice.reducer;
