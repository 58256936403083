import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { useForm } from 'react-hook-form';
import FqButton from '@ui/components/FqButton';
import { translate } from '@application/common/helpers/translate';
import 'react-html5-camera-photo/build/css/index.css';
import { verifyUserAsync } from '@application/features/shufftiPro/ShufftiProThunks';
import { generateUniqueNumberByDate } from '@application/common/helpers/date-time-helper';
import { APIStatus } from '@application/common/enums/api-status';
const KycForm = ({ userId }) => {
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.auth);
    const { status } = useAppSelector((state) => state.shufftiPro);
    const { handleSubmit, } = useForm();
    const getUniqueReference = () => {
        const uniqueNumber = generateUniqueNumberByDate();
        return `SP_REQUEST_${uniqueNumber}`;
    };
    const onSubmit = (userVerify) => {
        const reference = getUniqueReference();
        const email = currentUser?.email ?? '';
        const country = currentUser?.country.code ?? 'NL';
        const language = `EN`;
        const request = {
            reference,
            email,
            country,
            language
        };
        dispatch(verifyUserAsync(request));
    };
    return (_jsxs("form", { className: 'invoice__form', onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-md-12', children: _jsx("h3", { className: 'text-center', children: translate('kyc.welcomeTitle') }) }), _jsx("div", { className: 'col-md-12 pt-4', children: _jsx("p", { className: 'text-center', children: translate('kyc.welcomeMessage') }) })] }), _jsx("div", { className: 'd-flex justify-content-center pt-3', children: _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: translate('kyc.start') }) })] }));
};
export default KycForm;
