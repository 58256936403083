import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FqButton = ({ mode, icon, customClassName = '', size = 'md', isDisabled, ...props }) => {
    const buttonClass = size === 'md'
        ? `fq-button fq-button-md fq-button-${mode}`
        : size === 'lg'
            ? `fq-button fq-button-lg fq-button-${mode}`
            : `fq-button-sm fq-button-sm-${mode}`;
    const buttonDisabled = isDisabled ? 'fq-button-disabled' : '';
    return (_jsx("button", { className: `${customClassName} ${buttonClass} ${buttonDisabled}`, disabled: isDisabled, ...props, children: _jsxs("span", { children: [!!icon && _jsx(FontAwesomeIcon, { icon: icon, transform: 'left-10' }), " ", props.children] }) }));
};
export default FqButton;
