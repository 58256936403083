import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { usePagination, DOTS } from './use-pagination';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FqPagination = ({ pageNumber, totalPages, onPageChange }) => {
    const paginationRange = usePagination({ currentPage: pageNumber, totalPageCount: totalPages });
    const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
    return (_jsx(_Fragment, { children: !!(totalPages > 1) && (_jsx("nav", { children: _jsxs("ul", { className: 'pagination pagination-sm justify-content-center justify-content-md-end pt-3', children: [_jsx("li", { className: `page-item ${pageNumber === 1 ? 'disabled' : ''}`, children: _jsx("span", { className: 'page-link', onClick: () => onPageChange(pageNumber - 1), children: _jsx(FontAwesomeIcon, { icon: 'angles-left' }) }) }), paginationRange.map((currentPage, index) => {
                        if (currentPage === DOTS) {
                            return (_jsx("li", { children: _jsx("span", { children: "\u2026" }) }, index));
                        }
                        return (_jsx("li", { className: `page-item ${currentPage === pageNumber ? 'active' : ''}`, children: _jsx("span", { onClick: () => onPageChange(currentPage), className: 'page-link', children: currentPage }) }, index));
                    }), _jsx("li", { className: `page-item ${pageNumber === lastPage ? 'disabled' : ''}`, children: _jsx("span", { className: 'page-link', onClick: () => onPageChange(pageNumber + 1), children: _jsx(FontAwesomeIcon, { icon: 'angles-right' }) }) })] }) })) }));
};
export default FqPagination;
