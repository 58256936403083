import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { getDashboardAsync } from '@application/features/dashboard/dashboardThunk';
import { toggleCreateDebtorModal } from '@application/features/debtors/debtorSlice';
import { toggleCreateInvoiceModal } from '@application/features/invoices/invoiceSlice';
import { setFreelancerOutstandingInvoicesRequest } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import { getFreelancerOutstandingInvoicesAsync } from '@application/features/invoicesByStatus/invoicesByStatusThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import { useEffect } from 'react';
import CreateDebtorForm from '../debtors/CreateDebtorForm';
import CreateInvoiceForm from '../invoices/CreateInvoiceForm';
import DashboardCard from './Dashboard';
import UserOutstandingInvoices from './UserOutstandingInvoices';
function Dashboard() {
    const dispatch = useAppDispatch();
    const { dashboard } = useAppSelector((state) => state.dashboard);
    const { createInvoiceModalOpen } = useAppSelector((state) => state.invoice);
    const { createDebtorModalOpen } = useAppSelector((state) => state.debtor);
    const { invoices, status, freelancerOutstandingInvoicesRequest } = useAppSelector((state) => state.invoiceByStatus);
    const { currentUserDetail } = useAppSelector((state) => state.auth);
    useEffect(() => {
        dispatch(getDashboardAsync());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getFreelancerOutstandingInvoicesAsync(freelancerOutstandingInvoicesRequest));
    }, [dispatch, freelancerOutstandingInvoicesRequest]);
    const handleSearch = (e) => {
        dispatch(setFreelancerOutstandingInvoicesRequest(e.target.value));
    };
    const onAddInvoice = () => {
        dispatch(toggleCreateInvoiceModal());
    };
    const onAddDebtor = () => {
        dispatch(toggleCreateDebtorModal());
    };
    return (_jsxs("div", { className: 'pt-3', children: [dashboard && (_jsx(DashboardCard, { dashboard: dashboard, onAddInvoice: onAddInvoice, onAddDebtor: onAddDebtor, isUserDebtorExists: currentUserDetail?.isUserDebtorExists })), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: translate('invoice.invoices'), showSearch: true, onSearch: handleSearch }), children: _jsx(_Fragment, { children: _jsx(UserOutstandingInvoices, { invoices: invoices, status: status }) }) }), _jsx(FqDialog, { open: createInvoiceModalOpen, maxWidth: 850, title: translate('invoice.addInvoice'), onClose: () => dispatch(toggleCreateInvoiceModal()), children: _jsx(CreateInvoiceForm, {}) }), _jsx(FqDialog, { open: createDebtorModalOpen, maxWidth: 800, title: translate('debtor.addDebtor'), onClose: () => dispatch(toggleCreateDebtorModal()), children: _jsx(CreateDebtorForm, {}) })] }));
}
export default Dashboard;
