import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getFreelancerInvoicePackagesAsync } from './invoicePackageThunks';
const initialState = {
    status: APIStatus.IDLE,
    freelancerInvoicePackages: []
};
export const invoicePackageSlice = createSlice({
    name: 'invoicePackage',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFreelancerInvoicePackagesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getFreelancerInvoicePackagesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerInvoicePackages = action.payload;
        })
            .addCase(getFreelancerInvoicePackagesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export default invoicePackageSlice.reducer;
