import { getErrorPayload } from '@application/common/helpers/api-helper';
import { translate } from '@application/common/helpers/translate';
import FileApi from '@infrastructure/file-api';
import FreelancerApi from '@infrastructure/freelancer-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addSuccessNotification } from '../common/notificationSlice';
export const updateFreelancerAsync = createAsyncThunk('freelancer/update', async (freelancer, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
        const response = await FreelancerApi.updateFreelancerAccount(freelancer);
        dispatch(addSuccessNotification(translate('account.profileUpdatedSuccessfully')));
        dispatch(getFreelancerAsync());
        return fulfillWithValue(response.data);
    }
    catch (error) {
        const apiError = getErrorPayload(error);
        return rejectWithValue(apiError);
    }
});
export const getFreelancerAsync = createAsyncThunk('freelancer/getAccount', async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FreelancerApi.getFreelancerAccount();
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadCOCDocumentAsync = createAsyncThunk('freelancer/downloadCOC', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
export const downloadFreelancerTermsAndConditionsAsync = createAsyncThunk('freelancer/downloadFreelancerTermsAndConditions', async (file, { rejectWithValue, fulfillWithValue }) => {
    try {
        const response = await FileApi.downloadFile(file.folderName, file.fileName);
        return fulfillWithValue(response.data);
    }
    catch (error) {
        return rejectWithValue(getErrorPayload(error));
    }
});
