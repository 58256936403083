import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { logout } from '@application/features/auth/authSlice';
import { useAppDispatch } from '@application/store/useStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { translate } from '@application/common/helpers/translate';
import { toTitleCase } from '@application/common/helpers/title-case';
const UserProfileDropDown = ({ name, role }) => {
    const [accountDropDownShown, setAccountDropDownShown] = useState(false);
    const dispatch = useAppDispatch();
    const handleDropdownOfMainHeader = () => {
        setAccountDropDownShown(false);
    };
    document.addEventListener('click', handleDropdownOfMainHeader, true);
    return (_jsxs("div", { className: 'account__info', children: [_jsx("button", { className: `btn btn-link account__open ${accountDropDownShown ? 'open' : ''}`, onClick: () => setAccountDropDownShown(!accountDropDownShown), children: _jsxs("div", { className: 'account__data', children: [_jsx("img", { src: 'images/user.png', alt: 'app-user' }), _jsx("span", { children: toTitleCase(name) }), _jsx(FontAwesomeIcon, { icon: 'angle-down' })] }) }), _jsx("div", { className: `hide__account ${accountDropDownShown ? 'hidden__account' : ''}`, children: _jsx("div", { className: 'account__list', children: _jsxs("ul", { children: [role === 'Freelancer' && (_jsxs(_Fragment, { children: [_jsx("li", { children: _jsx(Link, { to: 'account', onClick: () => setAccountDropDownShown(false), children: translate('menu.myAccount') }) }), _jsx("li", { children: _jsx(Link, { to: 'support', onClick: () => setAccountDropDownShown(false), children: translate('menu.support') }) })] })), _jsx("li", { children: _jsx("a", { role: 'button', onClick: () => dispatch(logout()), children: translate('menu.signOut') }) })] }) }) })] }));
};
export default UserProfileDropDown;
