import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { toggleResponsiveMenu } from '@application/features/layout/layoutSlice';
import { useAppDispatch } from '@application/store/useStore';
import { useNavigate } from 'react-router-dom';
import LanguagesDropDown from './LanguagesDropDown';
const ResponsiveHeader = ({ balance, currentUser }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return (_jsxs("div", { className: 'mobail__menu__box d-lg-none', children: [_jsxs("div", { className: 'd-flex align-items-center', children: [_jsx("div", { className: 'logo__box', children: _jsx("figure", { className: 'logo', children: _jsx("button", { className: 'btn btn-link p-0', onClick: () => (currentUser?.role === 'Freelancer' ? navigate('/') : navigate('/admin/dashboard')), children: _jsx("img", { src: 'images/logo-two.svg', alt: 'logo' }) }) }) }), _jsx("div", { className: 'blance__mobail', children: _jsx("div", { className: 'balance', children: currentUser?.role === 'Freelancer' && (_jsxs("p", { children: [translate('common.balance'), " ", _jsxs("span", { children: ["\u20AC", balance] })] })) }) })] }), _jsxs("div", { className: 'd-flex align-items-center', children: [_jsx(LanguagesDropDown, {}), _jsx("div", { className: 'side-nav', children: _jsx("div", { className: 'single-header-right sidenav-btn-box', children: _jsx("div", { onClick: () => dispatch(toggleResponsiveMenu()), children: _jsx("figure", { children: _jsx("img", { src: 'images/bar.svg', alt: '' }) }) }) }) })] })] }));
};
export default ResponsiveHeader;
