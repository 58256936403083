import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InvoiceStatus } from '@application/common/enums/invoice-status';
import { translate } from '@application/common/helpers/translate';
import FqDashboardCard from '@ui/components/FqDashboardCard';
import { useNavigate } from 'react-router-dom';
const DashboardCard = ({ dashboard, onAddInvoice, onAddDebtor, isUserDebtorExists }) => {
    const navigate = useNavigate();
    const onAddInvoicesClick = () => {
        if (isUserDebtorExists)
            onAddInvoice();
    };
    const onViewInvoicesClick = () => {
        if (isUserDebtorExists)
            navigate(`/invoices/${InvoiceStatus.Approved},${InvoiceStatus.Received}`);
    };
    return (_jsxs("div", { className: 'row dashboard-cards', children: [_jsx(FqDashboardCard, { title: translate('dashboard.outstandingInvoices'), icon: 'file-invoice', count: dashboard.outStandingInvoices, linkTitle: translate('invoice.addInvoice'), onClick: onAddInvoicesClick, isEnabled: isUserDebtorExists }), _jsx(FqDashboardCard, { title: translate('dashboard.paidInvoices'), icon: 'file-invoice-dollar', count: dashboard.paidInvoices, linkTitle: translate('invoice.viewInvoices'), onClick: onViewInvoicesClick, isEnabled: isUserDebtorExists }), _jsx(FqDashboardCard, { title: translate('dashboard.acceptedDebtors'), icon: 'sack-dollar', count: dashboard.acceptedDebtors, linkTitle: translate('debtor.addDebtor'), onClick: onAddDebtor, isEnabled: true })] }));
};
export default DashboardCard;
