import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toggleReceivedInvoiceModal } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import FqButton from '@ui/components/FqButton';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { preventBackspaceAndDelete } from '@infrastructure/helpers';
const ReceivedInvoiceForm = ({ title, onReceivedInvoice, isDisabled }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const datepickerRef = useRef(null);
    const dispatch = useDispatch();
    const { control, formState: { errors } } = useForm();
    const handleDateChange = (date) => {
        if (date == null) {
            setSelectedDate(new Date());
        }
        else {
            setSelectedDate(date);
        }
    };
    useEffect(() => {
        preventBackspaceAndDelete(datepickerRef);
    });
    return (_jsxs("div", { className: "container", children: [_jsx("div", { className: "row justify-content-center mt-1", children: _jsxs("div", { className: "col-md-12", children: [_jsx("h5", { children: "Received Date" }), _jsx("div", { className: "d-flex mt-2", children: _jsx("div", { className: "me-3", children: _jsx(Controller, { name: 'receivedDate', control: control, render: () => (_jsx(DatePicker, { showMonthDropdown: true, showYearDropdown: true, maxDate: new Date(), popperPlacement: 'right', dropdownMode: 'select', dateFormat: 'dd/MM/yyyy', selected: selectedDate, onChange: handleDateChange, ref: datepickerRef })) }) }) })] }) }), _jsx("div", { className: "row justify-content-center mt-3", children: _jsx("div", { className: "col-md-12", children: _jsx("h5", { children: title }) }) }), _jsx("div", { className: "row justify-content-start mt-3", children: _jsx("div", { className: "col-md-6", children: _jsxs("div", { className: "d-flex justify-content-between", children: [_jsx(FqButton, { mode: 'primary', onClick: () => onReceivedInvoice(selectedDate), isDisabled: isDisabled, children: "Yes" }), _jsx(FqButton, { customClassName: 'ms-2', mode: 'danger', onClick: () => dispatch(toggleReceivedInvoiceModal()), children: "No" })] }) }) })] }));
};
export default ReceivedInvoiceForm;
