import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AdminMenuItems from './AdminMenuItems';
import FreelancerMenuItems from './FreelancerMenuItems';
const ResponsiveMenu = ({ userRole, userApproved, isOpen, onClose, isUserDebtorExists }) => {
    const navigate = useNavigate();
    return (_jsxs("div", { className: `hidden-sidebar side-navigation ${isOpen ? 'open' : ''}`, children: [_jsxs("div", { className: 'sidebar-content', children: [_jsx("div", { className: 'nav-logo text-center', children: _jsx("button", { className: 'btn btn-link p-0', onClick: () => (userRole === 'Freelancer' ? navigate('/') : navigate('/admin/dashboard')), children: _jsx("img", { src: 'images/mobail-logo.png', alt: 'logo' }) }) }), _jsx("span", { className: 'close-button side-navigation-close-btn icofont-close', children: _jsx(FontAwesomeIcon, { icon: 'times', onClick: () => onClose() }) })] }), _jsx("div", { className: 'dashboard__menu', children: userRole &&
                    (['Admin', 'SuperAdmin'].includes(userRole) ? (_jsx(AdminMenuItems, { isResponsiveMenu: true })) : (_jsx(FreelancerMenuItems, { userApproved: userApproved, isResponsiveMenu: true, isUserDebtorExists: isUserDebtorExists }))) })] }));
};
export default ResponsiveMenu;
