const signatures = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    '/9j/': 'image/jpg'
};
export const getMimeTypeFromBase64 = (base64String) => {
    for (var s in signatures) {
        if (base64String.indexOf(s) === 0) {
            return signatures[s];
        }
    }
};
export const getMimeTypeFromBase641 = (base64String) => {
    const mimeType = base64String.split(',')[0].split(';')[0].split(':')[1];
    return mimeType;
};
export const convertBase64toBlob = (content, contentType) => {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new File(byteArrays, 'aa', { type: contentType }); //statement which creates the blob
    return blob;
};
export const convertBase64ToBinary = (dataURI, filename) => {
    const arr = dataURI.split(',');
    var mime = getMimeTypeFromBase641(dataURI);
    var bstr = window.atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};
export const convertToPdfFile = (fileString) => {
    const byteCharacters = atob(fileString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    return blob;
};
