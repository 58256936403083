import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import FqValidation from '@ui/components/FqValidation';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { selectStyles } from '@ui/styles/select-style';
import FqButton from '@ui/components/FqButton';
import { DebtorStatus } from '@application/common/enums/debtor-status';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { toggleApproveFaqtoringContractModal } from '@application/features/debtors/debtorSlice';
import { useEffect } from 'react';
import { updateDebtorStatusAsync } from '@application/features/debtors/debtorThunks';
import { APIStatus } from '@application/common/enums/api-status';
const ApproveSignedContractForm = ({ debtorId, debtorName, onDownloadSignedFaqtoringContract, onFaqtoringContractApproved }) => {
    const dispatch = useAppDispatch();
    const { status } = useAppSelector((state) => state.debtor);
    const { control, handleSubmit, watch, register, setValue, formState: { errors } } = useForm({
        defaultValues: {
            debtorId: debtorId,
            status: 3
        }
    });
    const selectedStatus = watch('status');
    const statuses = [
        {
            label: 'Approved',
            value: DebtorStatus.Accepted
        },
        {
            label: 'Rejected',
            value: DebtorStatus.Rejected
        }
    ];
    useEffect(() => {
        if (selectedStatus === DebtorStatus.Accepted)
            setValue('reason', undefined);
    }, [selectedStatus, setValue]);
    const onSubmit = (status) => {
        dispatch(updateDebtorStatusAsync(status))
            .unwrap()
            .then((res) => {
            onFaqtoringContractApproved(status.debtorId);
        });
    };
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: "Company Name " }), _jsx("input", { type: 'text', value: debtorName, disabled: true, className: 'disabled' })] }) }), _jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsxs("div", { className: 'd-flex justify-content-between', children: [_jsx("label", { children: "Status" }), _jsx("button", { type: 'button', className: 'btn btn-link btn-link', onClick: () => onDownloadSignedFaqtoringContract(debtorId), children: "View faqtoring contract" })] }), _jsx(Controller, { name: 'status', control: control, rules: {
                                        required: {
                                            value: true,
                                            message: 'This field is required.'
                                        }
                                    }, render: ({ field: { onChange, value } }) => (_jsx(Select, { isSearchable: false, value: statuses.find((c) => c.value === value), options: statuses, styles: selectStyles, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.status })] }) }), selectedStatus === DebtorStatus.Rejected ? (_jsx("div", { className: 'col-lg-12 col-md-12', children: _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'form-label', children: "Reason" }), _jsx("textarea", { ...register('reason', {
                                        validate: {
                                            required: (value) => {
                                                if (!value && selectedStatus === DebtorStatus.Rejected)
                                                    return 'This field is required.';
                                            }
                                        }
                                    }), style: { height: 150, width: '100%', borderRadius: '3px' } }), _jsx(FqValidation, { fieldError: errors.reason })] }) })) : (_jsx(_Fragment, {}))] }), _jsxs("div", { className: 'text-end', children: [_jsx(FqButton, { mode: 'danger', type: 'button', onClick: () => dispatch(toggleApproveFaqtoringContractModal()), children: "Cancel" }), _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', isDisabled: status === APIStatus.PENDING, children: "Save" })] })] }));
};
export default ApproveSignedContractForm;
