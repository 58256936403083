import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { translate } from '@application/common/helpers/translate';
import { resetAuthApiState } from '@application/features/auth/authSlice';
import { signUpAsync } from '@application/features/auth/authThunks';
import { getCountriesAsync } from '@application/features/country/countryThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqValidation from '@ui/components/FqValidation';
import { selectStyles } from '@ui/styles/select-style';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
export const SignUp = () => {
    const navigate = useNavigate();
    const { status } = useAppSelector((state) => state.auth);
    const { countries, countriesDropdown } = useAppSelector((state) => state.country);
    const dispatch = useAppDispatch();
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const validatePassword = (password) => {
        if (password.length < 8)
            return 'Must contain atleast 8 characters.';
        if (!/[A-Z]+/.test(password))
            return 'Must contain atleast one uppercase letter.';
        if (!/[a-z]+/.test(password))
            return 'Must contain atleast one lowercase letter.';
        if (!/[0-9]+/.test(password))
            return 'Must contain atleast one number.';
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return 'Must contain atleast one special character.';
    };
    useEffect(() => {
        dispatch(getCountriesAsync());
    }, [dispatch]);
    useEffect(() => {
        if (status === APIStatus.SUCCESS) {
            dispatch(resetAuthApiState());
            navigate('/sign-up-success');
        }
    }, [dispatch, navigate, status]);
    const onSubmit = (user) => dispatch(signUpAsync(user));
    return (_jsx("div", { className: 'singin__container', children: _jsxs("div", { className: 'singin__page', children: [_jsx("div", { className: 'singin__page__left', children: _jsxs("div", { className: 'form__inner', children: [_jsxs("div", { className: 'from__title', children: [_jsx("h2", { children: translate('account.createAnAccount') }), _jsx("p", { children: translate('account.signupMessage') })] }), _jsx("form", { onSubmit: handleSubmit(onSubmit), id: 'contact-form', children: _jsxs("div", { className: 'all__from', children: [_jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.firstName') }), _jsx("input", { type: 'text', maxLength: 100, placeholder: 'Enter your first name', ...register('firstName', {
                                                        required: {
                                                            value: true,
                                                            message: 'This field is required.'
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: 'Should have minimum 2 characters.'
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: 'Maximum 100 characters are allowed.'
                                                        },
                                                        pattern: {
                                                            value: RegExp('^[a-z A-Z]+$'),
                                                            message: 'Invalid first name'
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.firstName })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.lastName') }), _jsx("input", { type: 'text', maxLength: 100, placeholder: 'Enter your last name', ...register('lastName', {
                                                        required: {
                                                            value: true,
                                                            message: 'This field is required.'
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: 'Should have minimum 2 characters.'
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: 'Maximum 100 characters are allowed.'
                                                        },
                                                        pattern: {
                                                            value: RegExp('^[a-z A-Z]+$'),
                                                            message: 'Invalid last name'
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.lastName })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.email') }), _jsx("input", { type: 'email', maxLength: 100, placeholder: 'Enter your email', ...register('email', {
                                                        required: {
                                                            value: true,
                                                            message: 'This field is required.'
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: 'Maximum 100 characters are allowed..'
                                                        },
                                                        pattern: {
                                                            value: /^(?!.*\.\.)[A-Z0-9]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Email address is not valid.'
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.email })] }), _jsxs("div", { className: 'form-group', children: [_jsx("label", { className: 'p_relative', children: translate('account.password') }), _jsx("input", { type: 'password', placeholder: 'Enter your password', ...register('password', {
                                                        required: {
                                                            value: true,
                                                            message: 'This field is required.'
                                                        },
                                                        validate: (value) => validatePassword(value)
                                                    }) }), _jsx(FqValidation, { fieldError: errors.password })] }), _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.country') }), _jsx(Controller, { name: 'countryId', control: control, rules: {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        }
                                                    }, render: ({ field: { onChange, value } }) => (_jsx(Select, { menuPlacement: 'top', placeholder: translate('account.country'), isSearchable: true, isClearable: true, styles: selectStyles, value: countriesDropdown?.find((c) => c.value === value), options: countriesDropdown, onChange: (val) => onChange(val?.value) })) }), _jsx(FqValidation, { fieldError: errors.countryId })] }), _jsx("div", { className: 'submit___btn', children: _jsx("div", { className: 'signin__button', children: _jsx("button", { className: 'btn__one', type: 'submit', name: 'submit__form', children: _jsx("span", { className: 'text', children: translate('account.createAccount') }) }) }) })] }) }), _jsx("div", { className: 'form__bottom', children: _jsxs("p", { children: [translate('account.alreadyHaveAccount'), " ", _jsx(Link, { to: '/login', children: translate('account.signin') })] }) })] }) }), _jsx("div", { className: 'singin__page__right', children: _jsx("div", { className: 'logo', children: _jsx("div", { className: 'logo', children: _jsx("img", { src: "images/faqtoring-login.jpg", alt: "Sign up page" }) }) }) })] }) }));
};
