import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { useEffect } from 'react';
import FqButton from '@ui/components/FqButton';
import { translate } from '@application/common/helpers/translate';
import { toggleKycResponseModal } from '@application/features/freelancer/freelancerSlice';
import { useNavigate } from 'react-router-dom';
const KycResponseForm = ({ userId }) => {
    const dispatch = useAppDispatch();
    const navaigate = useNavigate();
    const { shufftiProStatusDto } = useAppSelector((state) => state.shufftiPro);
    useEffect(() => {
    }, [dispatch]);
    return (_jsx("form", { className: 'invoice__form', children: _jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-md-12', children: _jsx("h3", { className: 'text-center', children: translate('kyc.successTitle') }) }), _jsx("div", { className: 'col-md-12 pt-4', children: _jsx("p", { className: 'text-center', children: translate('kyc.successMessage') }) }), _jsx("div", { className: 'col-md-12 pt-4', children: _jsx("p", { className: 'text-center', children: _jsx(FqButton, { mode: 'primary', type: 'button', customClassName: 'ms-2', onClick: () => {
                                dispatch(toggleKycResponseModal());
                                navaigate('/account?status=1');
                            }, children: translate('kyc.okay') }) }) })] }) }));
};
export default KycResponseForm;
