import { AppConsts } from '@application/common/constants/app-consts';
import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { createDebtorAsync, getActiveDebtorsAsync, getInactiveDebtorsAsync, getUserDebtorsAsync, updateDebtorStatusAsync, uploadSignedContractAsync, generateFaqtoringContractAsync, updateGenratedFactoringContractStatusAsync, getDebtorByIdAsync, downloadDebtorContractAsync } from './debtorThunks';
const activeDebtorsRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE
};
const inactiveDebtorsRequest = {
    pageNumber: AppConsts.GRID_DEFAULT_PAGE_NUMBER,
    pageSize: AppConsts.GRID_DEFAULT_PAGE_SIZE
};
const initialState = {
    status: APIStatus.IDLE,
    createDebtorModalOpen: false,
    viewDebtorModalOpen: false,
    signAgreementModalOpen: false,
    approveFaqtoringContractModalOpen: false,
    generateFaqtoringContractModalOpen: false,
    freelancerDebtors: [],
    filteredFreelancerDebtors: [],
    searchTerm: '',
    inactiveDebtorsRequest: inactiveDebtorsRequest,
    activeDebtorsRequest: activeDebtorsRequest
};
export const debtorSlice = createSlice({
    name: 'debtors',
    initialState,
    reducers: {
        toggleCreateDebtorModal(state) {
            state.createDebtorModalOpen = !state.createDebtorModalOpen;
        },
        toggleViewDebtorModal(state) {
            state.debtor = state.viewDebtorModalOpen ? undefined : state.debtor;
            state.viewDebtorModalOpen = !state.viewDebtorModalOpen;
        },
        toggleSignAgreementModal(state) {
            state.signAgreementModalOpen = !state.signAgreementModalOpen;
        },
        toggleApproveFaqtoringContractModal(state) {
            state.approveFaqtoringContractModalOpen = !state.approveFaqtoringContractModalOpen;
        },
        toggleGenerateFaqtoringContractModal(state) {
            state.generateFaqtoringContractModalOpen = !state.generateFaqtoringContractModalOpen;
        },
        setInactiveDebtorsRequest(state, action) {
            state.inactiveDebtorsRequest = action.payload;
        },
        setActiveDebtorsRequest(state, action) {
            state.activeDebtorsRequest = action.payload;
        },
        filterFreelancerDebtors(state, action) {
            if (action.payload) {
                state.filteredFreelancerDebtors = state.freelancerDebtors.filter((x) => x.companyName.toLowerCase().includes(action.payload.toLowerCase()));
                state.searchTerm = action.payload.toLowerCase();
            }
            else {
                state.filteredFreelancerDebtors = state.freelancerDebtors;
                state.searchTerm = '';
            }
        },
        resetDebtors(state) {
            state.activeDebtors = undefined;
            state.inactiveDebtors = undefined;
            state.activeDebtorsRequest = activeDebtorsRequest;
            state.inactiveDebtorsRequest = inactiveDebtorsRequest;
        },
        resetFreelancerDebtors(state) {
            state.freelancerDebtors = [];
            state.filteredFreelancerDebtors = [];
            state.searchTerm = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDebtorsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserDebtorsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.filteredFreelancerDebtors = state.freelancerDebtors = action.payload;
        })
            .addCase(getUserDebtorsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(downloadDebtorContractAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(downloadDebtorContractAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.blobDownloadDto = action.payload;
        })
            .addCase(downloadDebtorContractAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getInactiveDebtorsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getInactiveDebtorsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.inactiveDebtors = action.payload;
        })
            .addCase(getInactiveDebtorsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getActiveDebtorsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getActiveDebtorsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.activeDebtors = action.payload;
        })
            .addCase(getActiveDebtorsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(createDebtorAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(createDebtorAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.freelancerDebtors.push(action.payload);
            state.createDebtorModalOpen = false;
            if (state.searchTerm) {
                if (action.payload.companyName.toLowerCase().includes(state.searchTerm))
                    state.filteredFreelancerDebtors.push(action.payload);
            }
            else
                state.filteredFreelancerDebtors.push(action.payload);
        })
            .addCase(createDebtorAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(uploadSignedContractAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(uploadSignedContractAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.filteredFreelancerDebtors = state.filteredFreelancerDebtors.map((debtor) => {
                if (debtor.debtorId === action.payload.debtorId)
                    debtor = action.payload;
                return debtor;
            });
            state.freelancerDebtors = state.freelancerDebtors.map((debtor) => {
                if (debtor.debtorId === action.payload.debtorId)
                    debtor = action.payload;
                return debtor;
            });
        })
            .addCase(uploadSignedContractAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateDebtorStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateDebtorStatusAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateDebtorStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(generateFaqtoringContractAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(generateFaqtoringContractAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(generateFaqtoringContractAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateGenratedFactoringContractStatusAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateGenratedFactoringContractStatusAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(updateGenratedFactoringContractStatusAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getDebtorByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getDebtorByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.debtor = action.payload;
        })
            .addCase(getDebtorByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { filterFreelancerDebtors, toggleCreateDebtorModal, toggleViewDebtorModal, toggleSignAgreementModal, toggleApproveFaqtoringContractModal, toggleGenerateFaqtoringContractModal, setInactiveDebtorsRequest, setActiveDebtorsRequest, resetDebtors, resetFreelancerDebtors } = debtorSlice.actions;
export default debtorSlice.reducer;
