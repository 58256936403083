import { APIStatus } from '@application/common/enums/api-status';
import { getCurrentUserFromJwt } from '@application/common/helpers/jwt-helper';
import LocalStorageService from '@infrastructure/local-storage-service';
import { createSlice } from '@reduxjs/toolkit';
import { getCurrentUserDetailsAsync } from '../users/userThunks';
import { confirmEmailAsync, forgotPasswordAsync, loginAsync, refreshTokenAsync, resetPasswordAsync, signUpAsync, verifyTokenAsync } from './authThunks';
const initialState = {
    status: APIStatus.IDLE,
    currentUser: getCurrentUserFromJwt()
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.currentUser = undefined;
            LocalStorageService.removeAccessToken();
            LocalStorageService.removeRefreshToken();
            LocalStorageService.removeReferenceNumber();
            LocalStorageService.removeShufftiProStatus();
            state.apiError = undefined;
            state.status = APIStatus.IDLE;
            state.currentUserDetail = undefined;
        },
        resetAuthApiState(state) {
            state.status = APIStatus.IDLE;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(loginAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.currentUser = getCurrentUserFromJwt(action.payload.accessToken);
            LocalStorageService.setAccessToken(action.payload.accessToken);
            LocalStorageService.setRefreshToken(action.payload.refreshToken);
        })
            .addCase(loginAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getCurrentUserDetailsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getCurrentUserDetailsAsync.fulfilled, (state, action) => {
            state.currentUserDetail = action.payload;
        })
            .addCase(getCurrentUserDetailsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(refreshTokenAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(refreshTokenAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.currentUser = getCurrentUserFromJwt(action.payload.accessToken);
            LocalStorageService.setAccessToken(action.payload.accessToken);
            LocalStorageService.setRefreshToken(action.payload.refreshToken);
        })
            .addCase(refreshTokenAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
        });
        builder
            .addCase(signUpAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(signUpAsync.fulfilled, (state) => {
            state.status = APIStatus.SUCCESS;
        })
            .addCase(signUpAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(confirmEmailAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(confirmEmailAsync.fulfilled, (state) => {
            state.status = APIStatus.SUCCESS;
        })
            .addCase(confirmEmailAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(forgotPasswordAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(forgotPasswordAsync.fulfilled, (state) => {
            state.status = APIStatus.SUCCESS;
        })
            .addCase(forgotPasswordAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(resetPasswordAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(resetPasswordAsync.fulfilled, (state) => {
            state.status = APIStatus.SUCCESS;
        })
            .addCase(resetPasswordAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(verifyTokenAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(verifyTokenAsync.fulfilled, (state) => {
            state.status = APIStatus.SUCCESS;
        })
            .addCase(verifyTokenAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { logout, resetAuthApiState } = authSlice.actions;
export default authSlice.reducer;
