import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { logout, resetAuthApiState } from '@application/features/auth/authSlice';
import { confirmEmailAsync } from '@application/features/auth/authThunks';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
const ConfirmEmail = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const confirmationToken = searchParams.get('token');
    const confirmationEmail = searchParams.get('email');
    const dispatch = useAppDispatch();
    const { status } = useAppSelector((state) => state.auth);
    useEffect(() => {
        if (confirmationToken && confirmationEmail) {
            const confirmation = {
                email: confirmationEmail,
                token: confirmationToken.replaceAll(' ', '+')
            };
            dispatch(confirmEmailAsync(confirmation));
        }
    }, [confirmationEmail, confirmationToken, dispatch]);
    useEffect(() => {
        if (status === APIStatus.SUCCESS) {
            dispatch(resetAuthApiState());
            dispatch(logout());
            navigate('/login');
        }
    }, [dispatch, navigate, status]);
    return _jsx(_Fragment, {});
};
export default ConfirmEmail;
