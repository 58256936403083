import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { logout } from '@application/features/auth/authSlice';
import { toggleResponsiveMenu } from '@application/features/layout/layoutSlice';
import { useAppDispatch } from '@application/store/useStore';
import { translate } from '@application/common/helpers/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import './menu-items-style.css';
import { getMimeTypeFromBase64 } from '@application/common/helpers/file-helper';
import { downloadFreelancerTermsAndConditionsAsync } from '@application/features/freelancer/freelancerThunks';
import { useTranslation } from 'react-i18next';
const FreelancerMenuItems = ({ userApproved, isResponsiveMenu, isUserDebtorExists }) => {
    const { i18n } = useTranslation();
    const linkClassName = !userApproved ? 'nav-link-disabled' : '';
    const dispatch = useAppDispatch();
    const close = () => {
        if (isResponsiveMenu)
            dispatch(toggleResponsiveMenu());
    };
    var linkMyInvoiceClassName = '';
    if (!isUserDebtorExists || !userApproved) {
        linkMyInvoiceClassName = 'nav-link-disabled';
    }
    const downloadFreelancerTermsAndConditions = () => {
        const selectedLanguage = i18n.language;
        const fileDownloadInput = {
            folderName: 'TemplateDocuments',
            fileName: 'en.freelancertermsandconditions.pdf'
        };
        if (selectedLanguage === 'nl') {
            fileDownloadInput.fileName = 'nl.freelancertermsandconditions.pdf';
        }
        dispatch(downloadFreelancerTermsAndConditionsAsync(fileDownloadInput))
            .unwrap()
            .then((res) => {
            const mimeType = getMimeTypeFromBase64(res.file);
            const pdfWindow = window.open();
            pdfWindow?.document.write(`<title>TermsAndConditions</title>`);
            pdfWindow?.document.write(`<iframe width='100%' height='100%' src='data:${mimeType};base64,${encodeURI(res.file)}'></iframe>`);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("ul", { children: [_jsx("li", { children: _jsxs(NavLink, { to: '/', className: ({ isActive }) => (isActive ? `active ${linkClassName}` : `${linkClassName}`), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'square-poll-horizontal' }), " ", _jsx("span", { className: 'ms-2', children: translate('menu.myDashboard') })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: 'account', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'user' }), _jsxs("span", { className: 'ms-2', children: [" ", translate('menu.myAccount')] })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: 'debtors', className: ({ isActive }) => (isActive ? `active ${linkClassName}` : `${linkClassName}`), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'sack-dollar' }), _jsx("span", { className: 'ms-3', children: translate('menu.myDebtors') })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: 'invoices', className: ({ isActive }) => (isActive ? `active ${linkMyInvoiceClassName}` : `${linkMyInvoiceClassName}`), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'receipt' }), _jsx("span", { className: 'ms-3', children: translate('menu.myInvoices') })] }) }), _jsx("li", { children: _jsxs(NavLink, { to: 'support', className: ({ isActive }) => (isActive ? 'active' : ''), onClick: () => close(), children: [_jsx(FontAwesomeIcon, { icon: 'headset' }), _jsx("span", { className: 'ms-3', children: translate('menu.support') })] }) }), isResponsiveMenu && (_jsx("li", { children: _jsxs(NavLink, { to: '', className: ({ isActive }) => (isActive ? '' : ''), onClick: () => {
                                dispatch(toggleResponsiveMenu());
                                dispatch(logout());
                            }, children: [_jsx(FontAwesomeIcon, { icon: 'sign-out-alt' }), _jsx("span", { className: 'ms-3', children: translate('menu.signOut') })] }) }))] }), _jsx("div", { children: _jsxs("ul", { className: 'bottom-sidebar-ul', children: [_jsx("li", {}), _jsx("li", { children: _jsx("a", { className: 'bottom-sidebar-a', onClick: downloadFreelancerTermsAndConditions, children: translate('debtor.FreelancerTermsAndConditions') }) })] }) })] }));
};
export default FreelancerMenuItems;
