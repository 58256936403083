import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import { useEffect } from 'react';
import AccountFrom from './AccountFrom';
import { toggleKycErrorResponseModal, toggleKycModal, toggleKycResponseModal } from '@application/features/freelancer/freelancerSlice';
import KycForm from './KycForm';
import KycResponseForm from './KycResponseForm';
import KycErrorResponse from './KycErrorResponse';
const Account = () => {
    const dispatch = useAppDispatch();
    const { kycModalOpen, kycResponseModalOpen, kycErrorResponseModalOpen } = useAppSelector((state) => state.freelancer);
    const { shufftiProRedirectData } = useAppSelector((state) => state.shufftiPro);
    useEffect(() => {
        if (shufftiProRedirectData) {
            window.location.href = shufftiProRedirectData.verificationUrl;
        }
    }, [dispatch, shufftiProRedirectData]);
    return (_jsxs(_Fragment, { children: [_jsx(AccountFrom, {}), _jsx(FqDialog, { open: kycModalOpen, maxWidth: 800, title: '', onClose: () => dispatch(toggleKycModal(false)), hideCloseIcon: true, children: _jsx(KycForm, {}) }), _jsx(FqDialog, { open: kycResponseModalOpen, maxWidth: 800, title: '', onClose: () => dispatch(toggleKycResponseModal()), hideCloseIcon: true, children: _jsx(KycResponseForm, {}) }), _jsx(FqDialog, { open: kycErrorResponseModalOpen, maxWidth: 800, title: '', onClose: () => dispatch(toggleKycErrorResponseModal()), hideCloseIcon: true, children: _jsx(KycErrorResponse, {}) })] }));
};
export default Account;
