import { APIStatus } from '@application/common/enums/api-status';
import { createSlice } from '@reduxjs/toolkit';
import { getUsersAsync, getUserByIdAsync, updateUserAsync, getUserInvoicesAsync, getUserDebtorsAsync, getUserBalanceAsync, updateUserBalanceAsync, disableUserAsync, enableUserAsync } from './adminUserThunk';
import { UserStatus, UserStatusMap } from '@application/common/enums/user-status';
const initialState = {
    status: APIStatus.IDLE,
    userDetailsModalOpen: false,
    userInvoicesModalOpen: false,
    userDebtorsModalOpen: false,
    userOutStandingBalanceModalOpen: false,
    filteredUsers: [],
    userDebtors: [],
    searchTerm: '',
    getUserDetailsRequest: {
        pageNumber: 1,
        pageSize: 10
    },
    getUserInvoiceRequest: {
        pageNumber: 1,
        pageSize: 10
    },
    userStatuses: [
        { label: UserStatusMap.get(UserStatus.Pending), value: UserStatus.Pending.toString() },
        { label: UserStatusMap.get(UserStatus.Approved), value: UserStatus.Approved.toString() },
        { label: UserStatusMap.get(UserStatus.Disapproved), value: UserStatus.Disapproved.toString() },
        { label: UserStatusMap.get(UserStatus.PendingKyc), value: UserStatus.PendingKyc.toString() }
    ],
    disableUserConfirmOpen: false,
    enableUserConfirmOpen: false
};
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        toggleUserDetailsModal(state) {
            state.user = state.userDetailsModalOpen ? undefined : state.user;
            state.userDetailsModalOpen = !state.userDetailsModalOpen;
        },
        toggleUserInvoicesModal(state) {
            if (state.userInvoicesModalOpen) {
                state.userInvoices = undefined;
                state.getUserInvoiceRequest = {
                    pageNumber: 1,
                    pageSize: 10,
                    filter: ''
                };
            }
            state.userInvoicesModalOpen = !state.userInvoicesModalOpen;
        },
        toggleUserDebtorsModal(state) {
            state.userDebtors = state.userDebtorsModalOpen ? [] : state.userDebtors;
            state.userDebtorsModalOpen = !state.userDebtorsModalOpen;
        },
        toggleUserOutStandingBalanceModal(state) {
            state.balance = state.userOutStandingBalanceModalOpen ? undefined : state.balance;
            state.userOutStandingBalanceModalOpen = !state.userOutStandingBalanceModalOpen;
        },
        setUserDetailsRequest(state, action) {
            state.getUserDetailsRequest = action.payload;
        },
        setUserInvoicesRequest(state, action) {
            state.getUserInvoiceRequest = action.payload;
        },
        setSelectedStatus(state, action) {
            state.selectedStatus = action.payload;
        },
        toggleDisableUserConfirm(state) {
            state.disableUserConfirmOpen = !state.disableUserConfirmOpen;
        },
        toggleEnableUserConfirm(state) {
            state.enableUserConfirmOpen = !state.enableUserConfirmOpen;
        },
        resetUsers(state) {
            state.users = undefined;
            state.getUserDetailsRequest = {
                pageNumber: 1,
                pageSize: 10,
                filter: ''
            };
        },
        resetFreelancerBalance(state) {
            state.balance = undefined;
        },
        resetUserDetails(state) {
            state.user = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUsersAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.users = action.payload;
        })
            .addCase(getUsersAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserInvoicesAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserInvoicesAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.userInvoices = action.payload;
        })
            .addCase(getUserInvoicesAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserDebtorsAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserDebtorsAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.userDebtors = action.payload;
        })
            .addCase(getUserDebtorsAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserBalanceAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserBalanceAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.balance = action.payload;
        })
            .addCase(getUserBalanceAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateUserBalanceAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateUserBalanceAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.balance = action.payload;
        })
            .addCase(updateUserBalanceAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(getUserByIdAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(getUserByIdAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.user = action.payload;
        })
            .addCase(getUserByIdAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(updateUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(updateUserAsync.fulfilled, (state, action) => {
            state.status = APIStatus.IDLE;
            state.user = action.payload;
            state.userDetailsModalOpen = false;
        })
            .addCase(updateUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(disableUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(disableUserAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(disableUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
        builder
            .addCase(enableUserAsync.pending, (state) => {
            state.status = APIStatus.PENDING;
            state.apiError = undefined;
        })
            .addCase(enableUserAsync.fulfilled, (state) => {
            state.status = APIStatus.IDLE;
        })
            .addCase(enableUserAsync.rejected, (state, action) => {
            state.status = APIStatus.FAILED;
            state.apiError = action.payload;
        });
    }
});
export const { toggleUserDetailsModal, setUserDetailsRequest, setSelectedStatus, toggleUserInvoicesModal, toggleUserDebtorsModal, toggleUserOutStandingBalanceModal, setUserInvoicesRequest, toggleDisableUserConfirm, resetUserDetails, toggleEnableUserConfirm, resetUsers, resetFreelancerBalance } = userSlice.actions;
export default userSlice.reducer;
