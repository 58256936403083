import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { resetFreelancerBalance } from '@application/features/adminUsers/adminUserSlice';
import { resetFreelancerInvoice } from '@application/features/invoices/invoiceSlice';
import { getInvoiceByIdAsync } from '@application/features/invoices/invoiceThunk';
import { setPorcessedInvoicesRequest, toggleReceivedInvoiceModal, toggleViewProcessedInvoiceModal } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import { getProcessedInvoicesAsync, approvedProcessedInvoiceAsync } from '@application/features/invoicesByStatus/invoicesByStatusThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import FqPagination from '@ui/components/FqPagination';
import { useEffect, useState } from 'react';
import ApproveInvoiceForm from '../outstandingInvoices/ApproveInvoiceForm';
import ProcessedInvoicesList from './processedInvoicesList';
import ReceivedInvoiceForm from './receivedInvoiceForm';
const ProcessedInvoices = () => {
    const dispatch = useAppDispatch();
    const { status, processedInvoices, processedInvoicesRequest, receivedInvoiceModalOpen, viewProcessedInvoiceModalOpen } = useAppSelector((state) => state.invoiceByStatus);
    const { invoice } = useAppSelector((state) => state.invoice);
    const { balance } = useAppSelector((state) => state.user);
    const [invoiceId, setInvoiceId] = useState('');
    const [isDisabledStatus, setIsDisabledStatus] = useState(false);
    useEffect(() => {
        dispatch(getProcessedInvoicesAsync(processedInvoicesRequest));
    }, [dispatch, processedInvoicesRequest]);
    const handleSearch = (e) => {
        dispatch(setPorcessedInvoicesRequest({ ...processedInvoicesRequest, filter: e.target.value, pageNumber: 1 }));
    };
    const onPageChange = (currentPage) => {
        dispatch(setPorcessedInvoicesRequest({ ...processedInvoicesRequest, pageNumber: currentPage }));
    };
    const onReceivedInvoiceModal = (invoiceId) => {
        setInvoiceId(invoiceId);
        dispatch(toggleReceivedInvoiceModal());
    };
    const onReceivedInvoice = (date) => {
        setIsDisabledStatus(true);
        const obj = {
            invoiceId: invoiceId,
            date: date
        };
        dispatch(approvedProcessedInvoiceAsync(obj));
    };
    const viewProcessedInvoice = (invoiceId) => {
        dispatch(getInvoiceByIdAsync(invoiceId));
        dispatch(toggleViewProcessedInvoiceModal());
    };
    const onCloseViewProcessedInvoiceModal = () => {
        dispatch(toggleViewProcessedInvoiceModal());
        dispatch(resetFreelancerBalance());
        dispatch(resetFreelancerInvoice());
    };
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: 'Invoices' }), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: 'Processed Invoices', showSearch: true, onSearch: handleSearch }), children: _jsxs(_Fragment, { children: [_jsx(ProcessedInvoicesList, { invoices: processedInvoices?.items, status: status, onReceivedInvoice: onReceivedInvoiceModal, viewProcessedInvoice: viewProcessedInvoice }), processedInvoices && (_jsx(FqPagination, { pageNumber: processedInvoices?.pageNumber, totalPages: processedInvoices?.totalPages, onPageChange: onPageChange }))] }) }), _jsx(FqDialog, { open: receivedInvoiceModalOpen, onClose: () => dispatch(toggleReceivedInvoiceModal()), title: '', maxWidth: 540, children: _jsx(ReceivedInvoiceForm, { title: 'Are you sure invoice amount received?', onReceivedInvoice: onReceivedInvoice, isDisabled: isDisabledStatus }) }), _jsx(FqDialog, { open: viewProcessedInvoiceModalOpen, onClose: onCloseViewProcessedInvoiceModal, title: '', maxWidth: 900, children: _jsx(ApproveInvoiceForm, { invoice: invoice, balance: balance, onCancel: onCloseViewProcessedInvoiceModal }) })] }));
};
export default ProcessedInvoices;
