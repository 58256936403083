import { faqtoringApi } from './faqtoring-api';
const userVerify = async (userVerify) => {
    return faqtoringApi.post('shuffti-pro/verify', userVerify);
};
const getStatus = async (reference) => {
    return faqtoringApi.post('shuffti-pro/status', reference);
};
const ShufftiProApi = {
    userVerify,
    getStatus
};
export default ShufftiProApi;
