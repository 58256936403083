import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from '@application/common/helpers/translate';
import { createSupportAsync } from '@application/features/support/supportThunks';
import { useAppDispatch } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import FqValidation from '@ui/components/FqValidation';
import { useForm } from 'react-hook-form';
import ContactDetails from './ContactDetails';
const CreateSupport = () => {
    const dispatch = useAppDispatch();
    const { handleSubmit, register, reset, formState: { errors } } = useForm();
    const onSubmit = (support) => {
        dispatch(createSupportAsync(support));
        reset();
    };
    return (_jsxs("div", { className: 'contact__us', children: [_jsx("div", { className: 'contact__us__left', children: _jsx("div", { className: 'contact__form', children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), className: 'pt-3', noValidate: true, children: [_jsxs("div", { className: 'row', children: [_jsx("div", { className: 'col-lg-6 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.firstName') }), _jsx("input", { type: 'text', min: 2, maxLength: 25, ...register('firstName', {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: translate('validations.minimumCharactersAllowed', { count: 2 })
                                                        },
                                                        maxLength: {
                                                            value: 25,
                                                            message: translate('validations.maximumCharactersAllowed', { count: 25 })
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.firstName })] }) }), _jsx("div", { className: 'col-lg-6 col-md-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.lastName') }), _jsx("input", { type: 'text', min: 2, maxLength: 25, ...register('lastName', {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: translate('validations.minimumCharactersAllowed', { count: 2 })
                                                        },
                                                        maxLength: {
                                                            value: 25,
                                                            message: translate('validations.maximumCharactersAllowed', { count: 25 })
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.lastName })] }) }), _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('account.emailAddress') }), _jsx("input", { type: 'text', maxLength: 100, ...register('email', {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translate('validations.invalidEmailAddress')
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: translate('validations.maximumCharactersAllowed', { count: 100 })
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.email })] }) }), _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('support.subject') }), _jsx("input", { type: 'text', max: 20, ...register('subject', {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        },
                                                        maxLength: {
                                                            value: 20,
                                                            message: translate('validations.maximumCharactersAllowed', { count: 20 })
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.subject })] }) }), _jsx("div", { className: 'col-lg-12', children: _jsxs("div", { className: ' form-group', children: [_jsx("label", { children: translate('support.message') }), _jsx("textarea", { ...register('message', {
                                                        required: {
                                                            value: true,
                                                            message: translate('validations.fieldIsRequired')
                                                        },
                                                        maxLength: {
                                                            value: 300,
                                                            message: translate('validations.maximumCharactersAllowed', { count: 300 })
                                                        }
                                                    }) }), _jsx(FqValidation, { fieldError: errors.message })] }) })] }), _jsx("div", { className: 'contact__btn', children: _jsx(FqButton, { mode: 'primary', type: 'submit', customClassName: 'ms-2', children: translate('support.sendInquiry') }) })] }) }) }), _jsx(ContactDetails, {})] }));
};
export default CreateSupport;
