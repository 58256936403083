import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { setUserInvoicesRequest, toggleUserInvoicesModal } from '@application/features/adminUsers/adminUserSlice';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPagination from '@ui/components/FqPagination';
import { useEffect } from 'react';
import '../style.css';
import UserInvoicesList from './userInvoicesList';
const UserInvoices = ({ userInvoices, status }) => {
    const { getUserInvoiceRequest } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    useEffect(() => {
    }, []);
    const onPageChange = (currentPage) => {
        dispatch(setUserInvoicesRequest({ ...getUserInvoiceRequest, pageNumber: currentPage }));
    };
    const handleSearch = (e) => {
        dispatch(setUserInvoicesRequest({ ...getUserInvoiceRequest, filter: e.target.value, pageNumber: 1 }));
    };
    return (_jsxs("div", { className: "debtor__form", children: [_jsx(FqGrid, { header: _jsx(FqGridHeader, { title: 'User Invoices History', showSearch: true, onSearch: handleSearch }), children: _jsxs(_Fragment, { children: [_jsx(UserInvoicesList, { userInvoices: userInvoices?.items, status: status }), userInvoices && (_jsx(FqPagination, { pageNumber: userInvoices?.pageNumber, totalPages: userInvoices?.totalPages, onPageChange: onPageChange }))] }) }), _jsx("div", { className: "view__debtoe__popup__btn", children: _jsx(FqButton, { mode: 'danger', onClick: () => dispatch(toggleUserInvoicesModal()), children: "Cancel" }) })] }));
};
export default UserInvoices;
