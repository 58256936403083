import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { resetFreelancerBalance } from '@application/features/adminUsers/adminUserSlice';
import { toggleUpdateInvoiceStatusModal } from '@application/features/invoices/invoiceSlice';
import { setOutstandingInvoicesRequest } from '@application/features/invoicesByStatus/invoicesByStatusSlice';
import { getOutstandingInvoicesAsync } from '@application/features/invoicesByStatus/invoicesByStatusThunk';
import { useAppDispatch, useAppSelector } from '@application/store/useStore';
import FqDialog from '@ui/components/FqDialog';
import FqGrid from '@ui/components/FqGrid';
import FqGridHeader from '@ui/components/FqGrid/FqGridHeader';
import FqPageHeader from '@ui/components/FqPage/FqPageHeader';
import FqPagination from '@ui/components/FqPagination';
import { useEffect } from 'react';
import ApproveInvoiceForm from './ApproveInvoiceForm';
import OutStandingInvoiceList from './OutStandingInvoiceList';
const OutstandingInvoices = () => {
    const dispatch = useAppDispatch();
    const { status, outstandingInvoices, outstandingInvoicesRequest } = useAppSelector((state) => state.invoiceByStatus);
    const { invoice, updateInvoiceStatusModalOpen } = useAppSelector((state) => state.invoice);
    const { balance } = useAppSelector((state) => state.user);
    useEffect(() => {
        dispatch(getOutstandingInvoicesAsync(outstandingInvoicesRequest));
    }, [dispatch, outstandingInvoicesRequest]);
    const handleSearch = (e) => {
        dispatch(setOutstandingInvoicesRequest({ ...outstandingInvoicesRequest, filter: e.target.value, pageNumber: 1 }));
    };
    const onPageChange = (currentPage) => {
        dispatch(setOutstandingInvoicesRequest({ ...outstandingInvoicesRequest, pageNumber: currentPage }));
    };
    const onCloseUpdateInvoiceStatusModal = () => {
        dispatch(toggleUpdateInvoiceStatusModal());
        dispatch(resetFreelancerBalance());
    };
    return (_jsxs(_Fragment, { children: [_jsx(FqPageHeader, { title: 'Invoices' }), _jsx(FqGrid, { header: _jsx(FqGridHeader, { title: 'Outstanding Invoices', showSearch: true, onSearch: handleSearch }), children: _jsxs(_Fragment, { children: [_jsx(OutStandingInvoiceList, { invoices: outstandingInvoices?.items, status: status }), outstandingInvoices && (_jsx(FqPagination, { pageNumber: outstandingInvoices?.pageNumber, totalPages: outstandingInvoices?.totalPages, onPageChange: onPageChange }))] }) }), _jsx(FqDialog, { open: updateInvoiceStatusModalOpen, onClose: onCloseUpdateInvoiceStatusModal, title: 'Approve Invoice', maxWidth: 900, children: _jsx(ApproveInvoiceForm, { invoice: invoice, balance: balance, onCancel: onCloseUpdateInvoiceStatusModal }) })] }));
};
export default OutstandingInvoices;
