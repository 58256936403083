import { logout } from '@application/features/auth/authSlice';
import { refreshTokenAsync } from '@application/features/auth/authThunks';
import { store } from '@application/store';
import axios from 'axios';
import i18n from '../i18n';
import LocalStorageService from './local-storage-service';
const baseURL = process.env.REACT_APP_API_BASE_URL;
let refreshing_token = null;
export const faqtoringApi = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language || 'en'
    }
});
faqtoringApi.interceptors.request.use(async (config) => {
    const accessToken = LocalStorageService.getAccessToken();
    if (accessToken === null && config.url === 'token/refresh') {
        store.dispatch(logout());
        const controller = new AbortController();
        const cfg = {
            ...config,
            signal: controller.signal
        };
        controller.abort('Refresh token request cancelled');
        return cfg;
    }
    if (config.url !== 'token/refresh' && config.url !== 'account/authenticate') {
        if (config.headers)
            config.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return config;
});
faqtoringApi.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const config = error.config;
    if (error.response && error.response.status === 401 && !config._retry) {
        if (error.config?.url === 'account/authenticate' || error.config.url === 'account/forgot-password') {
            return Promise.reject(error);
        }
        else if (error.config.retry || error.config?.url === 'token/refresh') {
            refreshing_token = null;
            store.dispatch(logout());
        }
        else {
            config._retry = true;
            try {
                refreshing_token = refreshing_token ? refreshing_token : refresh_token();
                await refreshing_token;
                return faqtoringApi(config);
            }
            catch (err) {
                refreshing_token = null;
                return Promise.reject(err);
            }
        }
    }
    refreshing_token = null;
    return Promise.reject(error);
});
async function refresh_token() {
    await store.dispatch(refreshTokenAsync()).unwrap();
    refreshing_token = null;
}
