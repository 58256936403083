import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { APIStatus } from '@application/common/enums/api-status';
import { translate } from '@application/common/helpers/translate';
import { toggleUpdateInvoiceStatusModal } from '@application/features/invoices/invoiceSlice';
import { getInvoiceByIdAsync } from '@application/features/invoices/invoiceThunk';
import { useAppDispatch } from '@application/store/useStore';
import FqButton from '@ui/components/FqButton';
const OutStandingInvoiceList = ({ invoices = [], status }) => {
    const dispatch = useAppDispatch();
    const onApproveInvoice = (invoiceId) => {
        dispatch(getInvoiceByIdAsync(invoiceId));
        dispatch(toggleUpdateInvoiceStatusModal());
    };
    return (_jsx(_Fragment, { children: _jsxs("table", { className: 'table', children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "ID" }), _jsx("th", { children: "Freelancer Name" }), _jsx("th", { children: "Payable Invoice Amount" }), _jsx("th", { children: "Remaining Hours" }), _jsx("th", { children: "Status" })] }) }), invoices && invoices.length > 0 && (_jsx("tbody", { children: invoices.map((invoice) => {
                        return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.invoiceNo }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2', children: invoice.freelancerName }) }), _jsx("td", { children: _jsxs("div", { className: 'mt-2', children: ["\u20AC", invoice.payableInvoiceAmount.toLocaleString('nl', {
                                                maximumFractionDigits: 2
                                            })] }) }), _jsx("td", { children: _jsx("div", { className: `mt-2 ${invoice.remainingHours <= 24 ? 'text-danger' : ''}`, children: invoice.remainingHours }) }), _jsx("td", { children: _jsx("div", { className: 'mt-2 text-center', onClick: () => onApproveInvoice(invoice.id), children: _jsx(FqButton, { mode: 'primary', size: 'sm', children: "Review" }) }) })] }, invoice.id));
                    }) })), status === APIStatus.IDLE && invoices && !invoices.length && (_jsx("tbody", { children: _jsx("tr", { children: _jsx("td", { style: { textAlign: 'center', color: '#FF0000' }, colSpan: 5, children: translate('common.noRecordFound') }) }) }))] }) }));
};
export default OutStandingInvoiceList;
